import texts from "../../config/translationsConfig";

const ImageUploader = ({order, imageType, sendImageToServer, setIsLoading}) => {

    return (
        <div className="upload__container">
            <label htmlFor={`${imageType}-${order._id}`} className="submit">{texts.upload}</label>
            <input className="image_upload__btn"
                   multiple
                   type={'file'}
                   name={`${imageType}-${order._id}`}
                   id={`${imageType}-${order._id}`}
                   onChange={(e) => {
                       setIsLoading(true)
                       sendImageToServer(e, imageType, order);
                   }}/>
        </div>
    )
}

export default ImageUploader;