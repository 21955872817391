import {useEffect, useRef} from "react";
import texts from "../config/translationsConfig";

export function useFirstRender() {
    const firstRender = useRef(true);
    useEffect(() => {
        firstRender.current = false;
    }, []);
    return firstRender.current;
}

export function compareByCreationProgress(a, b) {
    if (a.creationProgress < b.creationProgress) {
        return -1;
    }
    if (a.creationProgress > b.creationProgress) {
        return 1;
    }
    return 0;
}

export function compareByCreationProgressForArtists(a, b) {
    if (a.creationProgress < b.creationProgress) {
        return 1;
    }
    if (a.creationProgress > b.creationProgress) {
        return -1;
    }
    return 0;
}

export const formatSize = (orderNumber, sizeString) => {
    if (orderNumber?.length > 4 && orderNumber.indexOf('-') === -1) return sizeString
    else return sizeString?.split(' ')[0];
    // if (sizeString.indexOf('FRAMED') !== -1) {
    //     if (sizeString.indexOf('White') !== -1) {
    //         size += ' White';
    //     } else if (sizeString.indexOf('Black') !== -1) {
    //         size += ' Black';
    //     } else {
    //
    //     }
    // }
}
export const formatFrameColor = (order, sizeString) => {
    let frameColor = '';
    if (order.orderNumber?.length > 4 && order.orderNumber.indexOf('-') === -1) return order?.frameColor
    else {
        if (sizeString && sizeString?.indexOf('FRAMED') !== -1) {
            if (sizeString?.indexOf('White') !== -1) {
                frameColor = 'White';
            } else if (sizeString?.indexOf('Black') !== -1) {
                frameColor = 'Black';
            } else if (sizeString?.toLowerCase().indexOf('free') !== -1) {
                frameColor = 'Free';
            } else {

            }
        }
        return frameColor;
    }
}
export const productNames = [
    {
        name: "BACKGROUND",
        nickname: 'With Background'
    },
    {
        name: "FULL-BODY",
        nickname: 'Full-Body'
    },
    {
        name: '$',
        nickname: 'Доплащане'
    }, {
        name: 'Oil',
        nickname: 'Oil'
    }, {
        name: 'OIL',
        nickname: 'Oil'
    },
    {
        name: 'Classic Water-color',
        nickname: 'Water'
    },
    {
        name: 'Tablet',
        nickname: 'Tablet'
    },
    {
        name: 'Modern',
        nickname: 'Modern'
    },
    {
        name: 'Memorial',
        nickname: 'Memorial'
    },
    {
        name: 'Hand Painting Classic',
        nickname: 'Classic'
    },
    {
        name: 'Custom Double Pet Portrait',
        nickname: 'Double'
    },
    {
        name: 'FILE',
        nickname: 'File'
    },
    {
        name: 'Digital',
        nickname: 'Digital'
    },
    {
        name: 'Corner Peekaboo',
        nickname: 'Dog Corner'
    },
    {
        name: 'Custom Cat Portrait',
        nickname: 'Cat Portrait'
    },
    {
        name: 'Demi Fisher Custom Dog',
        nickname: 'Demi Fisher'
    },
    {
        name: 'FRAMED Custom Dog Portrait',
        nickname: 'FRAMED Dog'
    },
    {
        name: 'Pet Portrait Gift Card',
        nickname: 'Gift Card'
    },
    {
        name: 'Custom Horse Portrait From Photo 100% Watercolor Hand Painting Peekaboo',
        nickname: 'Horse'
    },
    {
        name: 'Custom Hand-Painted Horse Portrait',
        nickname: 'Horse'
    },
    {
        name: 'Black & White',
        nickname: 'Black & White'
    },
    {
        name: 'T-SHIRT',
        nickname: 'T-SHIRT'
    },
    {
        name: 'Colored Pencils',
        nickname: 'Colored Pencils'
    },
    {
        name: 'Multi-Colors',
        nickname: 'Multi-Colors'
    },

    {
        name: 'ACRYLIC',
        nickname: 'ACRYLIC'
    },
    {
        name: 'Parrot',
        nickname: 'Parrot'
    },

    {
        name: 'Parrot',
        nickname: 'Parrot'
    },
    {
        name: 'Custom Dog Portrait',
        nickname: 'Dog Peekaboo'
    },
    {
        name: 'Custom Hand-Painted Dog Portrait',
        nickname: 'Dog Peekaboo'
    },
]
export const formatProduct = (orderNumber, fullName, orderNames = []) => {
    // console.log('functionUtils 69 :D',fullName, orderNumber)

    let product = orderNames.find(p => p.productName === fullName)
    if (orderNumber?.length > 4 && orderNumber.indexOf('-') === -1) return fullName;
    else if (product) return product.nickname;
    else return texts.unknownName;
}

export function replaceStringChar() {
    var str = 'Hello World';
    str = setCharAt(str, 4, 'a');
    alert(str);
}

function setCharAt(str, index, chr) {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
}

export function copyStringToClipboard(string) {
    var el = document.createElement('textarea');
    el.value = string;
    el.setAttribute('readonly', '');
    el.style = {position: 'absolute', left: '-9999px'};
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
}