import './Order.css'
import './DesignerOrder.css'
import React, {useEffect, useState} from "react";
import {useUserContext} from "../context/user/context";
import OrderDropdown from './OrderDropdown'
import texts from "../config/translationsConfig";
import {formatFrameColor} from "../utils/functionUtils";
import {formatSize, formatProduct} from "../utils/functionUtils";
import {serverFetch} from "../config/axiosConfig";
import uuid from "react-uuid";
import DesignerWorkingCheck from "./OrderPartials/DesignerWorkingCheck";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSheetPlastic} from "@fortawesome/free-solid-svg-icons";
import {useOrdersContext} from "../context/orders/context";

const DesignerOrder = ({order, orderIndex, user}) => {

    const [creationProgress, setCreationProgress] = useState(order.creationProgress);
    const [creationProgressSelected, setCreationProgressSelected] = useState(false);
    const [toggle, setToggle] = useState(false);
    const [sentToPrint, setSentToPrint] = useState(new Date(order.sentToPrint).toLocaleDateString("en-UK"));
    const [notesFor, setNotesFor] = useState(order.notesFor);
    const {productNames} = useOrdersContext()
    const updateOrder = async (query, update) => {
        const response = await serverFetch(`/updateOrder`, {
            method: "post",
            data: {store: order.store, query, update}
        })
    }

    const changeStatus = (newStatus) => {

        if (newStatus === 'printCenter') {
            updateOrder({_id: order._id}, {
                sentToPrint: Date.now(),
                sentToPrintIso: new Date(Date.now()).toLocaleDateString('en-UK')
            })
        }

        setCreationProgressSelected(true);
        setCreationProgress(newStatus);
        updateOrder({_id: order._id}, {
            creationProgress: newStatus,
            $push: {progressHistory: {date: Date.now(), user: user.nickname, status: newStatus}}
        })
    }


    const changeToggle = () => {
        setToggle(!toggle)
    }

    const changeNotesFor = (newNotesFor) => {

        setNotesFor(newNotesFor);
        setToggle(true);
        updateOrder({_id: order._id}, {notesFor: newNotesFor})
    }

    const formatStore = (store) => {
        if (store === 'dog-hero') return 'Dog Hero'
        else if (store === 'dog-land') return 'Dog Len'
        else if (store === 'custom-pet') return 'Custom Pet'
        else return texts.unknownName
    }

    // if 2 days have passed from designer taking - emergency is set
    // useEffect(() => {
    //     if (order.designDeadline < Date.now()) {
    //         updateOrder({_id: order._id}, {emergency: true})
    //     }
    // }, []);

    const shouldBeBlue = order.store === 'dog-hero' && order.orderNumber.indexOf('-') === -1 && order.orderNumber !== '';
    const shouldBeOrange = order.store === 'dog-land' && order.orderNumber.indexOf('-') === -1 && order.orderNumber !== '';
    const shouldBePoopy = order.store === 'custom-pet' && order.orderNumber.indexOf('-') === -1 && order.orderNumber !== '';

    return (
        <div
            className={`order__container ${(order.orderNumber.indexOf('-') !== -1 || order.orderNumber === '') ? "orderRowNoBorder" : ""}`}>
            <div
                className={`${order.emergency ? "orderRowRed" : order.priority ? "orderRowLightBlue" : ""} order__container__main`}>
                <div className="orderRow designerTable_date dropdown__clickable"
                     onClick={changeToggle}>{new Date(order.startDesign).toLocaleDateString("en-UK")}</div>
                <div className={`orderRow designerTable_num dropdown__clickable 
                    ${shouldBeBlue ? 'heroBackground' : shouldBeOrange ? 'landBackground' : shouldBePoopy ? "poopyBackground" : ''}`}
                     onClick={changeToggle}>
                    {order?.note ?
                        <><FontAwesomeIcon className="order_note_icon"
                                           title={texts.clientNote}
                                           icon={faSheetPlastic}
                                           color={'red'}
                        /><span>&nbsp;</span></> : ""}
                    {order.orderNumber}</div>
                <div className="orderRow designerTable_store dropdown__clickable"
                     onClick={changeToggle}>{formatStore(order.store)}</div>
                <div className="orderRow designerTable_size dropdown__clickable"
                     onClick={changeToggle}>{formatSize(order.orderNumber, order.portraitSize)}</div>
                <div className="orderRow designerTable_count dropdown__clickable"
                     onClick={changeToggle}>{order.frameColor !== '' ? order.frameColor : formatFrameColor(order, order.portraitSize)}</div>
                <div
                    className="orderRow designerTable_product dropdown__clickable"
                    onClick={changeToggle}>{formatProduct(order.orderNumber, order.productName,productNames)}</div>
                <div
                    className={`orderRow designerTable_deadline dropdown__clickable ${order.deadline < Date.now() ? 'deadline_red_color' : ''}`}
                    onClick={changeToggle}>{new Date(order.designDeadline).toLocaleDateString("en-UK")}</div>
                <div className="orderRow designerTable_status">
                    <select onChange={(e) => changeStatus(e.target.value)}
                            className={`status_options ${creationProgress ? creationProgress : ""}_option`}
                            value={creationProgress ? creationProgress : order.creationProgress !== 'None' ? order.creationProgress : texts.changeStatus}>
                        <option value={texts.changeStatus} disabled>{texts.changeStatus}</option>
                        <option value={"designer"}>{texts.designerRole}</option>
                        <option value={"printCenter"}>{texts.printCenterRole}</option>
                        <option value={"atoBeSpecified"}>{texts.toBeSpecified}</option>
                    </select>
                </div>
                <div
                    className="orderRow designerTable_sentToPrint dropdown__clickable"
                    onClick={changeToggle}>{sentToPrint.indexOf('01/01/1970') === -1 ? sentToPrint : ""}</div>
                <div className="orderRow designerTable_notes">
                    <div className={`dropdown__clickable`}
                    >
                        <select onChange={(e) => changeNotesFor(e.target.value)}
                                className={`status_options ${order.notes.length ? 'order_has_note' : ""}`}
                                value={notesFor !== '' ? notesFor : texts.upload}>
                            <option value={texts.upload} disabled>{texts.upload}</option>
                            <option value={"all"}>{texts.all}</option>
                            <option value={"designer"}>{texts.designerRole}</option>
                            <option value={"printCenter"}>{texts.printCenterRole}</option>
                            <option value={"artist"}>{texts.artistRole}</option>
                            <option value={"operator"}>{texts.operator}</option>
                        </select>
                    </div>
                </div>
                <div className="orderRow designerTable_working">
                    <DesignerWorkingCheck order={order} updateOrder={updateOrder} propToChange={'designerWorking'}/>
                </div>
            </div>
            <div className="orderTable__dropdown">
                {toggle ?
                    <OrderDropdown
                        key={uuid()}
                        order={order}
                        orderIndex={orderIndex}
                        notesFor={notesFor}
                    /> : ""}
            </div>
        </div>
    )
}

export default DesignerOrder;