// translation
import texts from "../config/translationsConfig";

// styles
import './OrderTableHeadings.css';

const OrderTableHeadings = ({printCenter}) => {
    return (
        <div className="orderTableHeadings__container">
            <div className="orderTable__heading orderTable_num">{texts.orderNum}</div>
            <div className="orderTable__heading orderTable_date">{texts.date}</div>
            <div className="orderTable__heading orderTable_customer">{texts.name}</div>
            <div className="orderTable__heading orderTable_size">{texts.size}</div>
            <div className="orderTable__heading orderTable_count">{texts.frameColor}</div>
            <div className="orderTable__heading orderTable_product">{texts.productName}</div>
            <div className="orderTable__heading orderTable_price">{texts.price}</div>
            <div className="orderTable__heading orderTable_deadline">{texts.deadline}</div>
            <div className="orderTable__heading orderTable_status">{texts.status}</div>
            <div className="orderTable__heading orderTable_designer">{texts.designerRole}</div>
            {!printCenter ? <div className="orderTable__heading orderTable_artist">{texts.artist}</div> : ''}
            {printCenter ? <div className="orderTable__heading orderTable_sentToPrint">{texts.sentToPrint}</div> : ''}
            <div className={`orderTable__heading orderTable_notes`}>{texts.notes}</div>
            {!printCenter ? <div className="orderTable__heading orderTable_priority">{texts.priority}</div> : ''}
            {printCenter ? <div className="orderTable__heading printTable_working">{texts.mark}</div> : ''}
        </div>
    )
}

export default OrderTableHeadings;