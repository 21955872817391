import React, {useEffect} from 'react';
import Modal from 'react-modal';
import {serverFetch} from '../../config/axiosConfig'

import texts from "../../config/translationsConfig";
import {useState} from "react";
import Button from "../Partials/Button/Button";
import {useUserContext} from "../../context/user/context"

import './ImageItem.css'
import Image from "../Partials/Image/Image";
import {faCircleChevronLeft, faCircleChevronRight} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const ImageItem = ({imageUrl, type, className, setNewImageDeleted, order}) => {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '85%',
            maxHeight: '85%'
        },
    };

    const {user} = useUserContext();

    const [openModal, setOpenModal] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [base64, setBase64] = useState('');
    const [fileExt, setFileExt] = useState('ext');
    const [currentImageUrl, setCurrentImageUrl] = useState(imageUrl);


    const getNextImage = async () => {
        try {
            let urlIndex = order[type].indexOf(currentImageUrl);
            let nextImageUrl;
            if (urlIndex <= order[type].length) nextImageUrl = order[type][++urlIndex];
            else setOpenModal(false);

            let b64 = await serverFetch(`/viewImage?imageUrl=${nextImageUrl}&&store=${order.store}&&date=${order.orderDate}`)
            setBase64(b64.data.base64);
            setCurrentImageUrl(nextImageUrl);
        } catch (e) {
        }
    }

    const getPreviousImage = async () => {
        try {
            let urlIndex = order[type].indexOf(currentImageUrl);
            let previousImageUrl;
            if (urlIndex > 0) previousImageUrl = order[type][--urlIndex];
            else setOpenModal(false);

            let b64 = await serverFetch(`/viewImage?imageUrl=${previousImageUrl}&&store=${order.store}&&date=${order.orderDate}`)
            setBase64(b64.data.base64);
            setCurrentImageUrl(previousImageUrl);
        } catch (e) {
        }
    }

    const confirmDelete = async () => {
        try {
            setOpenModal(false);
            const response = await serverFetch(`/deleteImage`, {
                method: "delete",
                data: {imageUrl, order, type}
            });
            //setIsDeleted(true);
            console.log('new image del: ', {imageUrl, type})
            setNewImageDeleted({imageUrl, type});
        } catch (e) {
        }
    }

    const downloadPdf = async () => {
        try {
            serverFetch(`/downloadPdf?orderId=${order._id}`, {responseType: 'arraybuffer'})
                .then(res => {
                    const url = window.URL.createObjectURL(new Blob([res.data]
                        , {type: "application/pdf"}))
                    let link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `${order.orderNumber ? order.orderNumber : 'petHero'}.pdf`);
                    document.body.appendChild(link);
                    link.click();
                })

        } catch (e) {
            console.log('pdf not downloaded')
        }
    }

    const [modalIsOpen, setIsOpen] = React.useState(false);


    async function afterOpenModal() {
        // references are now sync'd and can be accessed.
        let b64 = await serverFetch(`/viewImage?imageUrl=${currentImageUrl}&&store=${order.store}&&date=${order.orderDate}`)
        setBase64(b64.data.base64)
        //console.log('base: ', b64.data.base64)
        let urlExt = currentImageUrl.substring(currentImageUrl.length - 6, currentImageUrl.length);


        if (urlExt.toLowerCase().indexOf('heic') !== -1 || urlExt.indexOf('heif') !== -1) setFileExt('png');
        else setFileExt(urlExt.toLowerCase().split('.')[1]);

        console.log('url ext', urlExt.toLowerCase().split('.')[1])


    }

    function closeModal() {
        setIsOpen(false);
    }

    const shouldDelete = (type === 'images' && user.accessLevel > 7) ||
        (type === 'designImages' && user.accessLevel > 5) ||
        (type === 'artistPhotos')

    let [urlToRender, setToRender] = useState('')
    useEffect(() => {
        if (imageUrl.includes('http') || imageUrl.includes('base64')) setToRender(imageUrl)
        else {
            if (!base64) afterOpenModal()
            setToRender(base64)
        }
    }, [base64])
    return (
        !isDeleted ?
            <div className={className}>
                <div>
                    <img onClick={() => setIsOpen(true)} className="dropdown__image__img" src={urlToRender}
                         alt="изтегли"/>
                    {!openModal && shouldDelete ?
                        <div onClick={() => setOpenModal(true)}
                             className="add_tracking_button">{texts.delete}</div> : ""}
                </div>
                {openModal ? <div className="confirm_delete_modal">
                    <div>{texts.areYouSure} {texts.picture}?</div>
                    <div className="modal_btns">
                        <Button onClick={confirmDelete} title={texts.yes}
                                className={`edit_btn normal`}/>
                        <Button onClick={() => setOpenModal(false)} title={texts.no}
                                className={`delete_btn normal`}/>
                    </div>
                </div> : ""}

                <Modal
                    ariaHideApp={false}
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >

                    <button className="close__modal__btn" onClick={closeModal}>{texts.close}</button>
                    {/*{type === 'designImages' ? <button className="downloadPdf__btn" onClick={downloadPdf}>{texts.pdf}</button> : ''}*/}
                    <a download={`${order.orderNumber ? order.orderNumber : 'petHeroArt'}.${fileExt}`} href={base64}
                       onClick={closeModal}>
                        <button className="download__modal__btn">{texts.download}</button>
                    </a>
                    <a target="_blank"
                       href={imageUrl}>
                        <button className="newTab__modal__btn">{texts.openInNewTab}</button>
                    </a>
                    {fileExt === "ext" ? <Image className={'loading__image__container'} src={'480.gif'}/> :
                        <div className={"image_container"}>
                            <div className={'previous__btn__container'}>{order[type].indexOf(currentImageUrl) > 0 ?
                                <FontAwesomeIcon className="image__item__arrow__left"
                                                 title={texts.previousImage}
                                                 icon={faCircleChevronLeft}
                                                 size={'3x'}
                                                 onClick={getPreviousImage}/> : ""}</div>
                            <div className={'image__container'}><img
                                className="large__image__container"
                                src={base64}
                                alt="modalImage"/></div>
                            <div
                                className={'next__btn__container'}>  {order[type].indexOf(currentImageUrl) < order[type].length - 1 ?
                                <FontAwesomeIcon className="image__item__arrow__right"
                                                 title={texts.nextImage}
                                                 icon={faCircleChevronRight}
                                                 size={'3x'}
                                                 onClick={getNextImage}/> : ""}</div>

                        </div>
                    }
                </Modal>

            </div> : ""
    )
};

export default ImageItem;