import {
    DISPLAY_ALERT,
    CLEAR_ALERT,
    HANDLE_CHANGE
} from './actions';

//import { initialState } from './appContext';

const reducer = (state, action) => {
    if (action.type === DISPLAY_ALERT) {
        return {
            ...state,
            showAlert: true,
            alertType: 'danger',
            alertText: "Please provide all values!"
        }
    }
    if (action.type === CLEAR_ALERT) {
        return {
            ...state,
            showAlert: false,
            alertType: '',
            alertText: ""
        }
    }

    if (action.type === HANDLE_CHANGE) {
        return {
            ...state,
            [action.payload.name]: action.payload.value
        }
    }

    throw new Error(`no such action : ${action.type}`);
}

export default reducer;