import {useEffect, useState} from "react";

// context
import {useOrdersContext} from "../context/orders/context";
import {useUserContext} from "../context/user/context";

// components
import Order from "./Order";
import DesignerOrder from "./DesignerOrder";
import ArtistOrder from "./ArtistOrder";
import OrderTableHeadings from "./OrderTableHeadings";
import ArtistTableHeadings from "./Headings/ArtistTableHeadings";
import DesignerTableHeadings from "./Headings/DesignerTableHeadings";

//style
import './OrdersList.css'
import texts from "../config/translationsConfig";

// functions
import {compareByCreationProgress, compareByCreationProgressForArtists} from "../utils/functionUtils";
import uuid from "react-uuid";

const OrdersList = ({users, user, orders}) => {


    let artists = users?.filter(u => u.artist && !u.suspended);
    let designers = users?.filter(u => u.designer && !u.suspended);

    return (
        <div className="orderList__container">
            <div className="orderList__table__headings">
                {user?.accessLevel >= 9 ? <OrderTableHeadings printCenter={false}/> :
                    user?.accessLevel >= 6 ? <DesignerTableHeadings/> :
                        user?.accessLevel >= 4 ? <OrderTableHeadings printCenter={true}/> :
                            user?.accessLevel >= 2 ? <ArtistTableHeadings/> : ''
                }
            </div>
            <div className="orderList__tableRows">
                {user?.accessLevel >= 9 ? orders?.map((order, orderIndex) => {
                        return (
                            <Order
                                key={uuid()}
                                order={order}
                                orderIndex={orderIndex}
                                artists={artists}
                                designers={designers}
                                printCenter={false}
                                user={user}
                            />
                        )
                    }
                ) : user?.accessLevel === 6 ? orders?.filter(order => order.designerId === user?.designer &&
                    ['designer', 'printCenter', 'atoBeSpecified', 'fulfilled'].includes(order.creationProgress))
                    .sort(compareByCreationProgress)
                    .map((order, orderIndex) => {
                            return (
                                <DesignerOrder
                                    key={uuid()}
                                    order={order}
                                    orderIndex={orderIndex}
                                    user={user}
                                />
                            )
                        }
                    ) : user?.accessLevel >= 4 ? orders?.filter(order => order.creationProgress === 'printCenter' || order.creationProgress === 'finished')
                    ?.sort(compareByCreationProgressForArtists)
                    .map((order, orderIndex) => {
                            return (
                                <Order
                                    key={uuid()}
                                    order={order}
                                    orderIndex={orderIndex}
                                    artists={artists}
                                    designers={designers}
                                    printCenter={true}
                                    user={user}
                                />
                            )
                        }
                    ) : user?.accessLevel >= 2 ? orders?.filter(order => order.artistId === user?.artist &&
                    ['painting', 'finished', 'fulfilled', 'atoBeSpecified'].includes(order.creationProgress))
                    .sort(compareByCreationProgressForArtists)
                    .map((order, orderIndex) => {
                            return (
                                <ArtistOrder
                                    key={uuid()}
                                    order={order}
                                    orderIndex={orderIndex}
                                    user={user}
                                />
                            )
                        }
                    ) : <div className="access__denied">{texts.accessDenied}</div>
                }
            </div>

        </div>
    )
}

export default OrdersList;