import React, {createContext, useContext, useReducer} from 'react';
import reducer from './reducer';

import {
    GET_ORDERS_BEGIN,
    GET_ORDERS_ERROR,
    GET_ORDERS_SUCCESS,
    HANDLE_CHANGE,
    SET_ORDERS,
    SET_LEADERBOARD,
    UPDATE_ORDER_BEGIN,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_ERROR,
    UPLOAD_IMAGE_ERROR,
    UPLOAD_IMAGE_END,
    DELETE_IMAGE_END,
    DELETE_IMAGE_ERROR,
    GET_PRODNAMES_SUCCESS
} from './actions';
import {serverFetch} from "../../config/axiosConfig";

const initialState = {
    search: "",
    orders: [],
    ordersPage: 1,
    //sortBy: '',
    sortDirection: '',
    filter: {
        type: '',
        id: 'all',
        creationProgress: 'all',
        store: 'all',
        stores: ['dog-hero', 'dog-land', 'custom-pet']
    },
    paymentSum: 0,
    productNames: []
}

const OrdersContext = createContext();

const OrdersProvider = ({children}) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const setOrders = (orders) => {
        console.log('set orders')
        dispatch({
            type: SET_ORDERS,
            payload: {
                orders
            }
        });
    }
    const getProductNames = async () => {
        const response = await serverFetch(`/getProductNames`);

        console.log("SERVER RESPONSE:", response);

        let productNames = response.data.productNames;
        dispatch({
            type: GET_PRODNAMES_SUCCESS,
            payload: {
                productNames
            },
        })
    }
    const updateProductNickname = async (productId, nickname) => {
        const response = await serverFetch(`/updateProductNickname`, {
            method: "post",
            data: {productId, nickname}
        });

        console.log("SERVER RESPONSE:", response);

        let productNames = response.data.productNames;
        dispatch({
            type: GET_PRODNAMES_SUCCESS,
            payload: {
                productNames
            },
        })
    }

    const getOrders = async (type, limit = 2000, page = 0) => {
        let filterQuery = '';
        const {filter} = state;
        dispatch({type: GET_ORDERS_BEGIN});
        try {
            if (filter.id !== 'all') {
                if (filter.type === 'artist') filterQuery = `&&artist=${filter.id}`
                else if (filter.type === 'designer') filterQuery = `&&designer=${filter.id}`
            }

            // if (filter.store !== 'all') {
            //     filterQuery += `&&store=${filter.store}` //todo remove this
            // }

            filterQuery += filter.stores.map(a => `&&store=${a}`).join('')

            if (filter.creationProgress !== 'all') {
                filterQuery += `&&creationProgress=${filter.creationProgress}`
            }

            if (filter.priority) {
                filterQuery += `&&priority=true`
            }

            if (filter.emergency) {
                filterQuery += `&&emergency=true`
            }

            if (filter.mailOrPhone) {
                filterQuery += `&&mailOrPhone=${encodeURIComponent(filter.mailOrPhone)}`
                type = ''
            }

            if (filter.productName && filter.productName !== 'all') {
                filterQuery += `&productName=${filter.productName}`
            }
            const response = await serverFetch(`/getOrdersList?type=${type}${filterQuery}&&limit=${limit}`);

            console.log("SERVER RESPONSE:", response);

            let orders = response.data.orders;

            dispatch({
                type: GET_ORDERS_SUCCESS,
                payload: {
                    orders
                },
            })
        } catch (error) {
            console.log(error);
            dispatch({
                type: GET_ORDERS_ERROR,
                payload: {
                    error
                },
            })
        }

    }


    const updateOrder = async (store, query, update) => {
        dispatch({type: UPDATE_ORDER_BEGIN});
        try {
            const response = await serverFetch(`/updateOrder`, {
                method: "post",
                data: {store, query, update}
            })

            console.log("SERVER UPDATE RESPONSE:", response);
        } catch (error) {
            console.log(error);
            dispatch({
                type: UPDATE_ORDER_ERROR,
                payload: {
                    error
                },
            })
        }

    }

    const uploadImage = async ({base64Image, imageType, orderId}) => {
        try {
            const response = await serverFetch(`/uploadImage`, {
                method: "post",
                data: {base64Image, imageType, orderId}
            })
            console.log("SERVER UPDATE RESPONSE:", response);
            dispatch({
                type: UPLOAD_IMAGE_END,
                payload: {order: response.data.order}
            });
        } catch (error) {
            console.log(error);
            dispatch({
                type: UPLOAD_IMAGE_ERROR,
                payload: {
                    error
                },
            })
        }

    }

    const deleteImage = async (imageUrl, orderId, type) => {
        try {
            const response = await serverFetch(`/deleteImage`, {
                method: "delete",
                data: {imageUrl, orderId, type}
            })
            console.log("SERVER DELETE IMAGE RESPONSE:", response);
            dispatch({type: DELETE_IMAGE_END, order: response.data.order});
        } catch (error) {
            console.log(error);
            dispatch({
                type: DELETE_IMAGE_ERROR,
                payload: {
                    error
                },
            })
        }

    }


    const handleChange = ({name, value}) => {
        dispatch({type: HANDLE_CHANGE, payload: {name, value}});
    }

    return <OrdersContext.Provider value={{
        ...state,
        getOrders,
        setOrders,
        handleChange,
        updateOrder, getProductNames,
        uploadImage, deleteImage, updateProductNickname
    }}>
        {children}
    </OrdersContext.Provider>
}


const useOrdersContext = () => {
    return useContext(OrdersContext);
}

export {OrdersProvider, initialState, useOrdersContext}