import texts from "../../config/translationsConfig";
import {useEffect, useState} from "react";
import {useFirstRender} from "../../utils/functionUtils";
import './UserNote.css'

const UserNote = ({user, updateUser}) => {

    const [note, setNote] = useState(user?.note);
    const [debouncedNote, setDebouncedNote] = useState(note);
    const [editNote, setEditNote] = useState(false);

    const updateNote = async () => {
        try {
            await updateUser('User', {_id: user._id}, {note})
        } catch (e) {
            console.log('note not changed')
        }
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedNote(note);
        }, 1500);

        return () => {
            clearTimeout(timerId);
        };
    }, [note]);

    const firstRender = useFirstRender();

    useEffect(() => {
        if (!firstRender) {
            setEditNote(false);
            updateNote()
        }
    }, [debouncedNote]);

    return (
        <div className={`user_note_container`}>
            {editNote ?
                <input value={note} onChange={(e) => setNote(e.target.value)} className="edit_note_input"/> :
                note ? <div className={'dropdown__clickable'} title={texts.editNote}
                            onClick={() => setEditNote(!editNote)}>{note}</div> :
                    <span onClick={() => setEditNote(!editNote)} className={'dropdown__clickable'}>{texts.notes}</span>}
        </div>
    )
};

export default UserNote;