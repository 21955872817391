import Image from "../Image/Image";
import "./Button.css";
//Icons
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";


const Button = (props) => {
    const {title, type, className, src, onClick, name, value, fa, style, children} = props;

    return (
        <button
            name={name}
            value={value}
            onClick={onClick}
            className={`${className} btn_vavakada normal`}
            type={type}
            title={title}
            style={style}
        >
            {!!src ? (
                <>
                    <Image className="button__icon" src={src}/>
                    <span className="button__title">{title}</span>
                </>
            ) : (
                <span className="button__title__only">{title}</span>
            )}
            {fa ? <FontAwesomeIcon icon={fa}/> : null}
            {children}
        </button>
    );
};

Button.defaultProps = {
    title: "Click me",
    type: "submit",
    className: "normal",
};

export default Button;
