import React, {useReducer, useContext, createContext} from 'react';

import {serverFetch} from '../../config/axiosConfig';


import reducer from './reducer';

import {
    SETUP_USER_BEGIN,
    UPDATE_USER_BEGIN,
    SETUP_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    SETUP_USER_ERROR,
    UPDATE_USER_ERROR,
    GET_USERS_BEGIN,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
} from './actions.js';

import {useCookies} from 'react-cookie';
import {UPDATE_ORDER_BEGIN, UPDATE_ORDER_ERROR} from "../orders/actions";

const initialState = {
    user: null,
    isLoading: true,
    isLoggedIn: false,
    cookie: '',
    users: []
}
const isProduction = false;
const UserContext = createContext();

const UserProvider = ({children}) => {

    const [state, dispatch] = useReducer(reducer, initialState);

    const [cookies, setCookie] = useCookies(['userCookie']);

    const getUserData = async () => {

        dispatch({type: SETUP_USER_BEGIN});

        try {
            const response = await serverFetch('/getLocals');

            console.log("SERVER USER DATA RESPONSE:", response);
            const {data} = response;
            const user = data;

            dispatch({
                type: SETUP_USER_SUCCESS,
                payload: {
                    isLoading: false,
                    user,
                    cookie: cookies?.userCookie
                }
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: SETUP_USER_ERROR,
                payload: {
                    msg: error
                }

            })
        }
    }

    const getUsers = async () => {

        dispatch({type: GET_USERS_BEGIN});
        try {
            // todo get orders from db
            const response = await serverFetch('/getUsersList')

            console.log("SERVER USERLIST RESPONSE:", response);

            const users = response.data.users
            dispatch({
                type: GET_USERS_SUCCESS,
                payload: {
                    users
                },
            })
        } catch (error) {
            console.log(error);
            dispatch({
                type: GET_USERS_ERROR,
                payload: {
                    error
                },
            })
        }

    }

    const updateUser = async (type, query, update) => {
        dispatch({type: UPDATE_USER_BEGIN});
        try {
            const response = await serverFetch(`/update${type}`, {
                method: "post",
                data: {query, update}
            })

            console.log("SERVER UPDATE RESPONSE:", response);
        } catch (error) {
            console.log(error);
            dispatch({
                type: UPDATE_USER_ERROR,
                payload: {
                    error
                },
            })
        }

    }

    const deleteUser = async (user, type) => {
        try {
            const response = await serverFetch(`/deleteUser`, {
                method: "delete",
                data: {user, type}
            });
            console.log("SERVER DELETE RESPONSE:", response);
        } catch (error) {
            console.log(error);
        }

    }

    const updateLoggedIn = async (loggedIn, userCookie) => {

        dispatch({type: UPDATE_USER_BEGIN});

        try {

            dispatch({
                type: UPDATE_USER_SUCCESS,
                payload: {
                    isLoading: false,
                    isLoggedIn: loggedIn,
                    cookie: userCookie

                }
            });

        } catch (error) {
            console.log(error);
            dispatch({
                type: UPDATE_USER_ERROR,
                payload: {
                    msg: error
                }

            })
        }
    }


    return <UserContext.Provider value={{
        ...state,
        getUserData,
        updateLoggedIn,
        getUsers,
        updateUser,
        deleteUser
    }}>
        {children}
    </UserContext.Provider>
}

const useUserContext = () => {
    return useContext(UserContext);
}

export {UserProvider, initialState, useUserContext}