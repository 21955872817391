// components

// icons
import React, {useEffect, useState} from "react";
import {useUserContext} from "../context/user/context";
import texts from "../config/translationsConfig";
import './Reports.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uuid from "react-uuid";
import {shopifyDashboardServerHost} from "../config/axiosConfig";

const Reports = () => {


    const {user} = useUserContext();
    let today = new Date();
    let lastMonthStart = new Date(new Date(today.setMonth(today.getMonth() - 1)).setDate(1)).setHours(0, 0, 0, 0)
    let lastMonthEnd = new Date(lastMonthStart).setMonth(new Date(lastMonthStart).getMonth() + 1) - 1
    const [startDate, setStartDate] = useState(lastMonthStart)
    const [finalDate, setFinalDate] = useState(lastMonthEnd)
    const [reportShop, setReportShop] = useState('')

    return (user?.accessLevel >= 4 ?

        <div className="home__container">

            <div className={'reportInfo_heading'}>{texts.reportDownloaderHeading}</div>
            <span className={'report_info_date_container'}>
                    <span>{texts.chooseStartDate}</span>
                    <DatePicker className={'report__date_picker'} selected={startDate} dateFormat="dd/MM/yyyy HH:mm"
                                onChange={(date) => setStartDate(date.setHours(0, 0, 0, 0))}/>
                </span>
            <span className={'report_info_date_container'}>
                    <span>{texts.chooseFinalDate}</span>
                 <DatePicker className={'report__date_picker'}
                             selected={finalDate} dateFormat="dd/MM/yyyy HH:mm"
                             onChange={(date) => setFinalDate(date.setHours(23, 59, 59, 999))}/>
                </span><br/>
            <span className={'report_info_date_container'}>
                    <span>{texts.chooseReportStore}</span>
   <select name="status"
           onChange={(e) => {
               setReportShop(e.target.value)
               console.log(e.target.value)
           }}
           defaultValue={reportShop}
           className={`status_options heading__filter`}>
       <option value={""} disabled>Моля изберете </option>
                    <option className="heroBackground" value={"dog-hero"}>{texts.dogHero}</option>
                    <option className="landBackground" value={"dog-land"}>{texts.dogLand}</option>
                    <option className="poopyBackground" value={"custom-pet"}>{texts.customPet}</option>
                </select>
                 </span>

            {!!reportShop && <a href={`${shopifyDashboardServerHost}/downloadReport?shop=${reportShop}&startDate=${startDate}&endDate=${finalDate}`} target={"_blank"}><h1 style={{textAlign:"center"}}>{texts.download}</h1></a>}

        </div> : '')
}

export default Reports;