import './UserRow.css';
import texts from "../config/translationsConfig";
import Button from "./Partials/Button/Button";
import {useEffect, useState} from "react";
import {useUserContext} from "../context/user/context";
import {useFirstRender} from "../utils/functionUtils";
import {serverFetch} from "../config/axiosConfig";
import UserNote from "./AdminPartials/UserNote";

const UserRow = ({user, type}) => {

    const [newName, setNewName] = useState(user.nickname);
    const [debouncedName, setDebouncedName] = useState(newName);
    const [editName, setEditName] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [isDeleted, setIsDeleted] = useState(false);
    const [isSuspended, setIsSuspended] = useState(user.suspended);

    const updateUser = async (type, query, update) => {
        try {
            const response = await serverFetch(`/update${type}`, {
                method: "post",
                data: {query, update}
            })
        } catch (e) {
            console.log(e)
        }
    }

    const deleteUser = async (user, type) => {
        try {
            const response = await serverFetch(`/deleteUser`, {
                method: "delete",
                data: {user, type}
            });
        } catch (error) {
            console.log(error);
        }
    }

    const updateNickname = (setName) => {
        setNewName(setName);
    }

    const handleSuspend = () => {
        setIsSuspended(!isSuspended);
        updateUser('User', {_id: user._id}, {suspended: !isSuspended})
    }

    const handleDelete = () => {
        setOpenModal(true);
    }

    const confirmDelete = () => {
        setOpenModal(false);
        deleteUser(user, type);
        setIsDeleted(true);
    }


    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedName(newName);
        }, 1000);

        return () => {
            clearTimeout(timerId);
        };
    }, [newName]);

    const firstRender = useFirstRender();

    useEffect(() => {
        if (!firstRender) updateUser('User', {_id: user._id}, {nickname: newName})
    }, [debouncedName]);

    return (
        !isDeleted ?
            <div className="user_row_container">
                {openModal ? <div className="confirm_delete_modal">
                    <div>{texts.areYouSure}<span className="delete_user_nickname"> {newName} </span>?</div>
                    <div className="modal_btns">
                        <Button onClick={confirmDelete} title={texts.yes}
                                className={`edit_btn normal`}/>
                        <Button onClick={() => setOpenModal(false)} title={texts.no} className={`delete_btn normal`}/>
                    </div>
                </div> : <div className="user_row_values">
                    {editName ?
                        <div className="editTracking_container">
                            <input
                                onChange={(e) => updateNickname(e.target.value)} type="text"
                                className="edit_nickname_input" value={newName}/>
                            <Button onClick={() => setEditName(false)} title={texts.confirm}
                                    className={`confirm_btn normal`}/>
                        </div> :
                        <div className="user_edit_container">
                            <div className="admin__user__nickname">{newName}</div>
                            <Button onClick={() => setEditName(true)} title={texts.changeStatus}
                                    className={`edit_btn normal`}/>
                            <Button onClick={handleDelete} title={texts.delete} className={`delete_btn normal`}/>
                            {type !== 'PrintCenter' && !isSuspended ?
                                <Button onClick={handleSuspend} title={texts.suspend}
                                        className={`suspend_btn normal`}/> : ""}
                            {type !== 'PrintCenter' && isSuspended ? <Button onClick={handleSuspend} title={texts.start}
                                                                             className={`start_btn normal`}/> : ""}
                            {type === 'Artist' ?
                                <div className="artist__painting_count">{user.artist.assignedOrders.length}</div> : ''}
                            {type === 'Artist' ?
                                <div className={'user_note'}><UserNote user={user} updateUser={updateUser}/></div> : ''}
                        </div>}
                </div>
                }

            </div>
            : ""
    )
};

export default UserRow;