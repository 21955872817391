// translation
import texts from "../../config/translationsConfig";

// styles
import './DesignerTableHeadings.css';

const DesignerTableHeadings = () => {
    return (
        <div className="designerTableHeadings__container">
            <div className="designerTable__heading designerTable_startPainting">{texts.startPainting}</div>
            <div className="designerTable__heading designerTable_num">{texts.orderNum}</div>
            <div className="designerTable__heading designerTable_store">{texts.store}</div>
            <div className="designerTable__heading designerTable_size">{texts.size}</div>
            <div className="designerTable__heading designerTable_count">{texts.frameColor}</div>
            <div className="designerTable__heading designerTable_product">{texts.productName}</div>
            <div className="designerTable__heading designerTable_deadline">{texts.paintingDeadline}</div>
            <div className="designerTable__heading designerTable_status">{texts.status}</div>
            <div className="designerTable__heading designerTable_sentToPrint">{texts.sentToPrint}</div>
            <div className="designerTable__heading designerTable_notes">{texts.notes}</div>
            <div className="designerTable__heading designerTable_working">{texts.working}</div>
        </div>
    )
}

export default DesignerTableHeadings;