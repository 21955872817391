// translation
import texts from "../../config/translationsConfig";

// styles
import './ArtistTableHeadings.css';

const ArtistTableHeadings = () => {
    return (
        <div className="artistTableHeadings__container">
            <div className="artistTable__heading artistTable_startPainting">{texts.startPainting}</div>
            <div className="artistTable__heading artistTable_num">{texts.orderNum}</div>
            <div className="artistTable__heading artistTable_size">{texts.size}</div>
            <div className="artistTable__heading artistTable_count">{texts.frameColor}</div>
            <div className="artistTable__heading artistTable_product">{texts.productName}</div>
            <div className="artistTable__heading artistTable_deadline">{texts.paintingDeadline}</div>
            <div className="artistTable__heading artistTable_money">{texts.money}</div>
            <div className="artistTable__heading artistTable_artistStatus">{texts.status}</div>
            <div className="artistTable__heading artistTable_artistStatus">{texts.artistStatus}</div>
            <div className="artistTable__heading artistTable_notes">{texts.notes}</div>
        </div>
    )
}

export default ArtistTableHeadings;