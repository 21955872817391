// components
import OrdersList from "../components/OrdersList";

// icons
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowUp} from '@fortawesome/free-solid-svg-icons'
import {useEffect} from "react";
import {useOrdersContext} from "../context/orders/context";
import {useUserContext} from "../context/user/context";
import DownloadAll from "../components/Payment/DownloadAll";
import UnMarkAll from "../components/Payment/UnMarkAll";

const Home = () => {
    const scrollToTop = () => {
        document.body.scrollTo({top: 0, left: 0, behavior: "smooth"})
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    const {orders,productNames, getOrders,getProductNames} = useOrdersContext();
    const {getUsers, users, user} = useUserContext();

    useEffect(() => {
        getOrders('active')
        getUsers();
        getProductNames()
    }, [])
    return (
        <div className="home__container">
            <button onClick={scrollToTop}
                    style={{
                        background: "#359df7",
                        position: "fixed",
                        bottom: "35px",
                        right: "35px",
                        borderRadius: "7px",
                        zIndex: "10"
                    }}><FontAwesomeIcon
                icon={faArrowUp} size="3x"/></button>
            <br/>
            <OrdersList orders={orders} users={users} user={user}/>
            {user?.accessLevel === 4 ? <div className={'print_center_btn'}><DownloadAll orders={orders}/></div> : ''}
            {user?.accessLevel === 4 ? <div className={'print_center_btn'}><UnMarkAll orders={orders}/></div> : ''}
        </div>
    )
}

export default Home;