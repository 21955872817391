import Button from "../Partials/Button/Button";
import texts from "../../config/translationsConfig";
import {serverFetch} from "../../config/axiosConfig";
import React, {useState} from "react";
import './DownloadAll.css'

const UnMarkAll = ({orders}) => {

    const [openModal, setOpenModal] = useState(false);

    const unmarkAll = async () => {
        for (let order of orders) {
            if (order.creationProgress === 'printCenter' && order.downloaded === true) await updateOrder(order, {_id: order._id}, {downloaded: false});
        }

    }

    const confirmDelete = async () => {
        try {
            setOpenModal(false);
            await unmarkAll();
            window.location.reload();
        } catch (e) {
        }


    }

    const updateOrder = async (order, query, update) => {
        try {
            const response = await serverFetch(`/updateOrder`, {
                method: "post",
                data: {store: order.store, query, update}
            })
            console.log('response: ', response)
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={'download_all_container'}>
            {!openModal ?
                <Button className={'pay_all_button unmarkAll_btn'} title={texts.unMarkAll}
                        onClick={() => setOpenModal(true)}/>
                : <div className="confirm_delete_modal">
                    <div className="pay_all_text">{texts.areYouSureUnMarkAll}?</div>
                    <div className="modal_btns">
                        <Button onClick={confirmDelete} title={texts.yes}
                                className={`edit_btn normal`}/>
                        <Button onClick={() => setOpenModal(false)} title={texts.no}
                                className={`delete_btn normal`}/>
                    </div>
                </div>}
        </div>
    )
}

export default UnMarkAll;