import {useState} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {serverFetch} from "../config/axiosConfig";

// components
import Button from '../components/Partials/Button/Button';

// styles
import "./Home.css";

// translation
import texts from "../config/translationsConfig";

const Login = () => {
    const loginBtnStyle = {backgroundColor: "#012453", color: "white"}
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [message, setMessage] = useState("");

    const handleLogin = async (event) => {
        event.preventDefault();
        let response = await serverFetch.post('/loginUser', {email, password})
        setEmail('');
        setPassword('');
        setMessage(response.data.msg);
        switch (response.data.msg) {
            case 'Успешно влизане!':
                window.location.href = '/'
                break
            default:
                console.log(response)
                break
        }
    }
    return (
        <Container className="login_container">
            <form>
                <Row>
                    <Col xs={1} lg={2}>
                    </Col>
                    <Col xs={10} lg={8} className="center__col">
                        <Row className="form__col">
                            <input
                                placeholder={texts.emailOrNickname}
                                className="mail__input"
                                type="text"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Row>
                        <Row className="pass__row">
                            <input
                                placeholder={texts.password}
                                className="inline__input"
                                type="password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <Button
                                className="button__form"
                                style={loginBtnStyle}
                                title={texts.login}
                                onClick={handleLogin}
                            />
                        </Row>
                    </Col>
                    <Col xs={1} lg={2}>
                    </Col>
                    <h5>{message}</h5>
                </Row>
            </form>
        </Container>
    )
};

export default Login;