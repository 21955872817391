import Button from "../Partials/Button/Button";
import texts from "../../config/translationsConfig";
import {serverFetch} from "../../config/axiosConfig";
import React, {useState} from "react";

const PayAll = ({orders}) => {

    const [openModal, setOpenModal] = useState(false);

    const payAll = async () => {
        orders.map(async (o) => {
            await updateOrder(o, {_id: o._id}, {artistPaid: true})
        });
        window.location.reload();
    }

    const confirmDelete = async () => {
        try {
            setOpenModal(false);
            await payAll()
        } catch (e) {
        }


    }

    const updateOrder = async (order, query, update) => {
        try {
            const response = await serverFetch(`/updateOrder`, {
                method: "post",
                data: {store: order.store, query, update}
            })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={'payall_container'}>
            {!openModal ? <Button className={'pay_all_button'} title={texts.payAll} onClick={() => setOpenModal(true)}/>
                : <div className="confirm_delete_modal">
                    <div className="pay_all_text">{texts.areYouSurePayAll}?</div>
                    <div className="modal_btns">
                        <Button onClick={confirmDelete} title={texts.yes}
                                className={`edit_btn normal`}/>
                        <Button onClick={() => setOpenModal(false)} title={texts.no}
                                className={`delete_btn normal`}/>
                    </div>
                </div>}
        </div>
    )
}

export default PayAll;