import texts from "../config/translationsConfig";
import {useEffect, useState} from "react";
import {useUserContext} from "../context/user/context";
import {serverFetch} from "../config/axiosConfig";

const NoteInput = ({setNotes, notes, order, notesFor}) => {

    const formatNotesFor = (notesFor) => {
        let accessLevel = null;
        switch (notesFor) {
            case 'all':
                break;
            case 'printCenter':
                accessLevel = 4;
                break;
            case 'artist':
                accessLevel = 2;
                break;
            case 'operator':
                accessLevel = 9;
                break;
            case 'designer':
                accessLevel = 6;
                break;
            default:
                break;
        }
        return accessLevel;
    }


    const {user} = useUserContext();

    const [noteValue, setNoteValue] = useState('');

    const updateOrderNotes = async (newNote) => {
        try {
            const newNotes = [...notes];
            newNotes.push({timestamp: Date.now(), user: user.nickname, text: newNote});
            setNotes(newNotes);
            setNoteValue('')
            const response = await serverFetch(`/updateOrder`, {
                method: "post",
                data: {
                    store: order.store,
                    query: {_id: order._id},
                    update: {
                        $push: {
                            notes: {
                                timestamp: Date.now(),
                                user: user.nickname, text: newNote, accessLevel: formatNotesFor(notesFor)
                            }
                        }
                    }
                }
            })
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className="editTracking_container">
            <input
                onChange={(e) => setNoteValue(e.target.value)}
                className="order__notes" value={noteValue}/>
            <div onClick={() => {
                if (noteValue !== '') {
                    updateOrderNotes(noteValue)
                }
            }}
                 className="add_tracking_button">{texts.confirm}</div>
        </div>
    )
}

export default NoteInput;