import './CreateOrder.css';
import texts from "../config/translationsConfig";
import CreateOrderInput from "../components/CreateOrder/CreateOrderInput";
import {useEffect, useState} from "react";
import {serverFetch} from "../config/axiosConfig";
import CreateOrderSelect from "../components/CreateOrder/CreateOrderSelect";
import Button from "../components/Partials/Button/Button";
import {useUserContext} from "../context/user/context";

const CreateOrder = () => {

    const {user} = useUserContext();

    const [nextNumber, setNextNumber] = useState('');
    const [store, setStore] = useState(texts.selectStore);
    const [customerName, setCustomerName] = useState('');
    const [customerEmail, setCustomerEmail] = useState('');
    const [address, setAddress] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [zip, setZip] = useState('');
    const [country, setCountry] = useState('');
    const [phone, setPhone] = useState('');
    const [productName, setProductName] = useState('');
    const [portraitSize, setPortraitSize] = useState('');
    const [frameColor, setFrameColor] = useState('');
    const [message, setMessage] = useState('');


    const getLastOrderNumber = async () => {
        let response = await serverFetch(`/getLastOrderNumber`, {
            method: "get",
        });
        console.log('num : ', response.data.lastOrderNumber)
        setNextNumber((Number(response.data.lastOrderNumber) + 1).toString());
    }

    const submitOrderData = async (e) => {
        e.preventDefault();
        const data = {
            store,
            orderNumber: nextNumber,
            customerName,
            customerEmail,
            address,
            city,
            state,
            zip,
            country,
            phone,
            productName,
            portraitSize,
            frameColor
        }

        if (store !== texts.selectStore && customerName && customerEmail && address && city
            && state && zip && country && productName && portraitSize) {
            let response = await serverFetch(`/manualCreateOrder`, {
                method: "post",
                data
            });
            console.log('response on create order: ', response);
            window.location.href = '/';
        } else {
            setMessage(texts.chooseStore);
        }

    }

    useEffect(() => {
        getLastOrderNumber()
    }, [])

    return (
        user?.accessLevel > 7 ?

            <div className={'createOrder_mainContainer'}>
                <div className={'createOrder_heading'}>
                    {/* orderNumber */}
                    <div>{texts.manualOrderNumber}:</div>
                    <div className={'createOrder_manual_number'}>{nextNumber}</div>
                </div>
                <form>
                    <div className={'createOrder_row'}>
                        <CreateOrderSelect
                            options={[
                                {value: 'dog-hero', text: texts.dogHero},
                                {value: 'dog-land', text: texts.dogLand},
                                {value: "custom-pet", text: texts.customPet}]}
                            className={'createOrder_store'}
                            setValue={setStore}
                            value={store}
                            heading={texts.store}
                            disabled={{value: texts.selectStore, text: texts.selectStore}}
                            required={true}
                        />
                    </div>
                    <div className={'createOrder_row'}>
                        <CreateOrderInput
                            className={'customerName_input'}
                            type={'text'}
                            heading={texts.customerName}
                            required={true}
                            value={customerName}
                            setValue={setCustomerName}
                        />
                    </div>
                    <div className={'createOrder_row'}>
                        <CreateOrderInput
                            className={'customerEmail_input'}
                            type={'text'}
                            heading={texts.customerEmail}
                            required={true}
                            value={customerEmail}
                            setValue={setCustomerEmail}
                        />
                    </div>
                    <div className={'createOrder_row'}>
                        <CreateOrderInput
                            className={'shippingAddress_input'}
                            type={'text'}
                            heading={texts.address}
                            required={true}
                            value={address}
                            setValue={setAddress}
                        />
                    </div>
                    <div className={'createOrder_row'}>
                        <CreateOrderInput
                            className={'shippingCity_input'}
                            type={'text'}
                            heading={texts.city}
                            required={true}
                            value={city}
                            setValue={setCity}
                        />
                    </div>
                    <div className={'createOrder_row'}>
                        <CreateOrderInput
                            className={'shippingState_input'}
                            type={'text'}
                            heading={texts.stateCode}
                            required={true}
                            value={state}
                            setValue={setState}
                        />
                    </div>
                    <div className={'createOrder_row'}>
                        <CreateOrderInput
                            className={'shippingZip_input'}
                            type={'text'}
                            heading={texts.zipCode}
                            required={true}
                            value={zip}
                            setValue={setZip}
                        />
                    </div>
                    <div className={'createOrder_row'}>
                        <CreateOrderInput
                            className={'shippingCountry_input'}
                            type={'text'}
                            heading={texts.country}
                            required={true}
                            value={country}
                            setValue={setCountry}
                        />
                    </div>
                    <div className={'createOrder_row'}>
                        <CreateOrderInput
                            className={'shippingPhone_input'}
                            type={'text'}
                            heading={texts.phone}
                            required={false}
                            value={phone}
                            setValue={setPhone}
                        />
                    </div>
                    <div className={'createOrder_row'}>
                        <CreateOrderInput
                            className={'product_input'}
                            type={'text'}
                            heading={texts.productName}
                            required={true}
                            value={productName}
                            setValue={setProductName}
                        />
                    </div>
                    <div className={'createOrder_row'}>
                        <CreateOrderInput
                            className={'size_input'}
                            type={'text'}
                            heading={texts.size}
                            required={true}
                            value={portraitSize}
                            setValue={setPortraitSize}
                        />
                    </div>
                    <div className={'createOrder_row'}>
                        <CreateOrderSelect
                            options={[{value: '', text: texts.noFrame}, {
                                value: 'Black',
                                text: 'Black'
                            }, {value: 'White', text: 'White'}]}
                            className={'createOrder_frame'}
                            setValue={setFrameColor}
                            value={frameColor}
                            heading={texts.frame}
                            required={false}
                        />
                    </div>
                    <h5>{message}</h5>
                    <div className={'createOrder_submit'}>
                        <br/>
                        {store !== texts.selectStore ?
                            <Button
                                className="button__form createUserBtn "
                                title={texts.createNewUserBtnText}
                                onClick={e => submitOrderData(e)}
                            /> : ''}
                        <br/>
                        <br/>
                    </div>

                </form>

            </div> : ''
    )
}

export default CreateOrder;