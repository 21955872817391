const CreateOrderInput = ({className, type, heading, required, value, setValue}) => {

    return (
        <div>
            <div className={'input_heading'}>{heading} :</div>
            <input
                required={required}
                placeholder={heading}
                className={className}
                type={type}
                value={value}
                onChange={(e) => setValue(e.target.value)}/>
        </div>
    )
}

export default CreateOrderInput;