import {BrowserRouter, Route, Routes} from "react-router-dom";

//Pages
import Home from "./pages/Home";
import Login from "./pages/Login";
import Heading from "./components/Heading";
import Admin from "./pages/Admin";
import FulFilled from "./pages/FulFilled";
import Payment from "./pages/Payment";
import CreateOrder from "./pages/CreateOrder";
import ArtistInfo from "./pages/ArtistInfo";
import Reports from "./pages/Reports";

function App() {

    return (
        <BrowserRouter>
            <div className="app">
                <Heading/>
                <div className="app__body">
                    <Routes>
                        <Route exact path="/" element={<Home/>}/>
                        <Route exact path="/payment" element={<Payment/>}/>
                        <Route exact path="/login" element={<Login/>}/>
                        <Route exact path="/admin" element={<Admin/>}/>
                        <Route exact path="/fulfilled" element={<FulFilled/>}/>
                        <Route exact path="/createOrder" element={<CreateOrder/>}/>
                        <Route exact path="/artistInfo" element={<ArtistInfo/>}/>
                        <Route exact path="/reports" element={<Reports/>}/>
                    </Routes>
                </div>
            </div>
        </BrowserRouter>
    );
}

export default App;
