import {useCookies} from "react-cookie";
import Image from "./Partials/Image/Image";
import {Link} from "react-router-dom";
import texts from "../config/translationsConfig";

import './Heading.css';

import {useUserContext} from "../context/user/context";
import {useOrdersContext} from "../context/orders/context";
import React, {useEffect, useState} from "react";
import Button from "./Partials/Button/Button";
import uuid from "react-uuid";
import {productNames, useFirstRender} from "../utils/functionUtils";
import {useAppContext} from "../context/appContext";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faPaw} from '@fortawesome/free-solid-svg-icons'

const shopifyDashboardReactHost = process.env.NODE_ENV !== "development" ? "https://shop-art.xyz" : "http://localhost:2090";

const Heading = () => {

    const [cookies, setCookie, removeCookie] = useCookies(['userCookie']);
    const {user, getUserData, users, getUsers, updateUser} = useUserContext();
    const {filter, getOrders, orders, handleChange} = useOrdersContext();
    const {paymentSum} = useAppContext();

    const [getFiltered, setGetFiltered] = useState(false)
    const [newFilter, setNewFilter] = useState(filter)
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState(search);
    const logOutBtnStyle = {backgroundColor: "#692250", color: "white", fontWeight: "bold"}
    const [showFilters, setShowFilters] = useState(false)

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedSearch(search);
        }, 1000);

        return () => {
            clearTimeout(timerId);
        };
    }, [search]);

    const firstRender = useFirstRender();

    useEffect(() => {
        if (!firstRender) {
            let filterObj = {creationProgress: 'all', stores: ['dog-hero', 'dog-land','custom-pet']};
            filterObj.mailOrPhone = search
            setNewFilter(filterObj)
            handleChange({name: 'filter', value: filterObj});
            console.log('filter obj: ', filterObj)

            setGetFiltered(true)
        }
    }, [debouncedSearch]);


    const logoutUser = () => {
        updateUser('User', {_id: user._id}, {cookie: '-'})
        removeCookie('userCookie')
        window.location.href = '/login';
    }

    const countOrders = (orders) => {
        if (user.accessLevel === 4) orders = orders.filter(o => o.creationProgress === 'printCenter' || o.creationProgress === 'finished');
        if (user.accessLevel === 2) orders = orders.filter(order => order.artistId === user?.artist &&
            ['painting', 'finished', 'fulfilled', 'atoBeSpecified'].includes(order.creationProgress));
        if (user.accessLevel === 6) orders = orders.filter(order => order.designerId === user?.designer &&
            ['designer', 'printCenter', 'atoBeSpecified', 'fulfilled'].includes(order.creationProgress));
        return orders?.length;
    }

    const countMonthlySum = () => {
        let sum = 0;
        if (orders) {
            const date = new Date();
            let firstDayUnix = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
            orders.filter(o => o.orderDate >= firstDayUnix).map((o) => {
                sum += o.priceAfterDiscount;
            })
        }
        return Math.floor(sum);
    }

    const assignFilter = (newUserFilter) => {
        let filterObj = newFilter;
        filterObj.id = newUserFilter;
        let isArtist = users.find(u => u.artist?._id === newUserFilter);
        let isDesigner = users.find(u => u.designer?._id === newUserFilter);
        if (isArtist) filterObj.type = 'artist';
        else if (isDesigner) filterObj.type = 'designer';
        setNewFilter(filterObj);
        handleChange({name: 'filter', value: filterObj});
        setGetFiltered(true)
    }

    const assignStatusFilter = (newCreationProgress) => {
        let filterObj = newFilter;
        filterObj.creationProgress = newCreationProgress;
        setNewFilter(filterObj)
        handleChange({name: 'filter', value: filterObj});
        setGetFiltered(true)
    }

    const assignStoreFilter = (newStore) => {
        let filterObj = newFilter;
        if (newStore === 'all') filterObj.stores = ['dog-land', 'dog-hero','custom-pet']
        else filterObj.stores = [newStore];
        setNewFilter(filterObj)
        handleChange({name: 'filter', value: filterObj});
        setGetFiltered(true)
    }
    const assignProductFilter = (newProductName) => {
        let filterObj = newFilter;
        filterObj.productName = newProductName;
        setNewFilter(filterObj)
        handleChange({name: 'filter', value: filterObj});
        setGetFiltered(true)
    }

    const assignPriorityFilter = (newPriority) => {
        let filterObj = newFilter;
        if (newPriority === 'all') {
            filterObj.emergency = false;
            filterObj.priority = false;
        } else if (newPriority === 'emergency') {
            filterObj.emergency = true;
            filterObj.priority = false;
        } else if (newPriority === 'priority') {
            filterObj.priority = true;
            filterObj.emergency = false;
        }
        setNewFilter(filterObj)
        handleChange({name: 'filter', value: filterObj});
        setGetFiltered(true)
    }

    useEffect(() => {
        if (window.location.pathname !== '/login') getUserData()
        if (window.location.pathname === '/admin') getUsers();
    }, [])

    useEffect(() => {
        if (window.location.pathname !== '/login' && !firstRender) {
            if (window.location.pathname === '/') getOrders('active');
            else if (window.location.pathname === '/fulfilled') getOrders('fulfilled');
            else if (window.location.pathname === '/payment') getOrders('payment');
        }
        setGetFiltered(false)
    }, [getFiltered])


    return (
        <div className="navContainer">

            {!showFilters && <>
                {user?.accessLevel > 7 ? <div className="navLink admin__link">
                    {/*<Link to={'/admin'}><Image className={'admin_logo'} src="admin_logo.png"*/}
                    {/*                           alt={'adminLogo'}/></Link>*/}
                    <a href={`${shopifyDashboardReactHost}/admin`}><Image className={'admin_logo'}
                                                                          src="admin_logo.png"
                                                                          alt={'adminLogo'}
                                                                          title={texts.adminPanel}/></a>
                </div> : ''}
                <div className="navLink fulfilled__link">
                    {/*<a href={`${shopifyDashboardReactHost}/fulfilled`}><Image className={'fulfilled_logo'}*/}
                    {/*                                                          src="fulfilled_logo_blue.png"*/}
                    {/*                                                          alt={'fulfilledLogo'}/></a>*/}
                    <Link to={'/fulfilled'}><Image className={'fulfilled_logo'}
                                                   src="fulfilled_logo_blue.png"
                                                   alt={'fulfilledLogo'}
                                                   title={texts.fulfilledOrders}/></Link>
                </div>
                <div className="navLink active__link">
                    <h3 className="welcome__heading">
                        <a href={`${shopifyDashboardReactHost}/`}>
                            {/*<Image src="petHeroLogoTrans.png" alt={'petHeroLogo'}/>*/}
                            <span className={'heading__active'}><FontAwesomeIcon
                                icon={faPaw}/> Активни</span>
                        </a>
                        {/*<Link to={'/'}><Image src="petHeroLogoTrans.png"*/}
                        {/*                      alt={'petHeroLogo'}/></Link>*/}
                    </h3>
                </div>
                {showFilters && <>
                    {/*shop filter*/}
                    {user?.accessLevel > 3 ? <div className="navLink filters__container">
                        {window.location.pathname !== '/admin' ?
                            <div><span>{texts.orderCount}</span><span
                                className={'heading_ordersCount'}>{countOrders(orders)}</span>
                            </div> : ''}
                        <label htmlFor={"status"} className="filter__label">{texts.store}:</label>
                        <select name="status"
                                onChange={(e) => assignStoreFilter(e.target.value)}
                                defaultValue={filter.store}
                                className={`status_options heading__filter`}>
                            <option value={'all'}>{texts.all}</option>
                            <option className="heroBackground" value={"dog-hero"}>{texts.dogHero}</option>
                            <option className="landBackground" value={"dog-land"}>{texts.dogLand}</option>
                            <option className="poopyBackground" value={"custom-pet"}>{texts.customPet}</option>
                        </select>
                    </div> : ""}

                    {/*status filter*/}
                    {user?.accessLevel > 3 ? <div className="navLink filters__container">
                        <label htmlFor={"status"} className="filter__label">{texts.status}:</label>
                        <select name="status"
                                onChange={(e) => assignStatusFilter(e.target.value)}
                                defaultValue={filter.creationProgress}
                                className={`status_options heading__filter`}>
                            <option value={'all'}>{texts.all}</option>
                            <option className="None_option" value={"None"}>{texts.newText}</option>
                            <option className="designer_option" value={"designer"}>{texts.designerRole}</option>
                            <option className="printCenter_option"
                                    value={"printCenter"}>{texts.printCenterRole}</option>
                            <option className="ready_option" value={"ready"}>{texts.ready}</option>
                            <option className="atoBeSpecified_option"
                                    value={"atoBeSpecified"}>{texts.toBeSpecified}</option>
                            <option className="painting_option" value={"painting"}>{texts.painting}</option>
                            <option className="finished_option" value={"finished"}>{texts.finished}</option>
                        </select>
                    </div> : ""}

                    {/*people filter*/}
                    {user?.accessLevel > 7 ? <div className="navLink filters__container">
                        <label htmlFor={"users"} className="filter__label">{texts.show}:</label>
                        <select name="users"
                                onChange={(e) => assignFilter(e.target.value)}
                                className="status_options heading__filter"
                                value={filter.id}>
                            <option value={'all'}>{texts.all}</option>
                            <optgroup label={texts.designers}>
                                {users?.filter(u => u.designer && !u.suspended).map((designer) => {
                                    return <option key={uuid()}
                                                   value={designer.designer._id}>{designer.nickname}</option>
                                })}
                            </optgroup>
                            <optgroup label={texts.artists}>
                                {users?.filter(u => u.artist && !u.suspended).map((artist) => {
                                    return <option key={uuid()} value={artist.artist._id}>{artist.nickname}</option>
                                })}
                            </optgroup>
                        </select>
                    </div> : ""}

                    {/*NB filter*/}
                    {user?.accessLevel > 7 ? <div className="navLink filters__container">
                        <label htmlFor={"priority"} className="filter__label">{texts.priority}</label>
                        <select name="priority"
                                onChange={(e) => assignPriorityFilter(e.target.value)}
                                defaultValue={filter}
                                className={`status_options heading__filter`}>
                            <option value={'all'}>{texts.all}</option>
                            <option className="emergency_option" value={"emergency"}>{texts.emergency}</option>
                            <option className="priority_option" value={"priority"}>{texts.important}</option>
                        </select>
                    </div> : ""}
                </>}

                {user?.accessLevel > 7 ?
                    <div className="navLink filters__container">
                        <input placeholder={texts.searchInput}
                               onChange={(e) => setSearch(e.target.value)}
                               className="order__notes" value={search}/>
                    </div>
                    : ""}
                {user?.accessLevel > 7 && window.location.pathname === '/payment' ?
                    <div className="monthlySum">
                        {user?.accessLevel > 9 ? <div>{countMonthlySum()}</div> : ''}
                        <div className="payment_container">
                            <span>{texts.paymentSum}: {paymentSum}</span>
                        </div>
                    </div> : user?.accessLevel > 7 && window.location.pathname !== '/payment' ?
                        <div className="navLink fulfilled__link">
                            <Link to={'/payment'}><Image className={'payment_logo'}
                                                         src="payment_logo.png"
                                                         alt={'paymentLogo'}
                                                         title={texts.payment}/>
                            </Link>
                        </div>
                        : ''}
                {user?.accessLevel === 4 ?
                    <Link to={"/reports"}>Print Reports</Link>
                    : ''}
                {user ? <div className="navLink paw_link">
                    {/*<Link to={'/admin'}><Image className={'admin_logo'} src="admin_logo.png"*/}
                    {/*                           alt={'adminLogo'}/></Link>*/}
                    <a title={texts.appText}
                       href={'https://play.google.com/store/apps/details?id=com.deyandanailov.ShopifyDashboardApp&pli=1'}
                       style={{
                           background: "transparent",
                           position: "fixed",
                           top: "20px",
                           left: "20px",
                           borderRadius: "7px",
                           zIndex: "10"
                       }}><Image src={'icon.png'}/></a>
                </div> : ''}
            </>}

            {showFilters && <>
                {/*shop filter*/}
                {user?.accessLevel > 3 ? <div className="navLink filters__container">
                    {window.location.pathname !== '/admin' ?
                        <div><span>{texts.orderCount}</span><span
                            className={'heading_ordersCount'}>{countOrders(orders)}</span>
                        </div> : ''}
                    <label htmlFor={"status"} className="filter__label">{texts.store}:</label>
                    <select name="status"
                            onChange={(e) => assignStoreFilter(e.target.value)}
                            defaultValue={filter.store}
                            className={`status_options heading__filter`}>
                        <option value={'all'}>{texts.all}</option>
                        <option className="heroBackground" value={"dog-hero"}>{texts.dogHero}</option>
                        <option className="landBackground" value={"dog-land"}>{texts.dogLand}</option>
                        <option className="poopyBackground" value={"custom-pet"}>{texts.customPet}</option>
                    </select>
                </div> : ""}

                {/*status filter*/}
                {user?.accessLevel > 3 ? <div className="navLink filters__container">
                    <label htmlFor={"status"} className="filter__label">{texts.productName}:</label>
                    <select name="status"
                            onChange={(e) => assignProductFilter(e.target.value)}
                            defaultValue={filter.productName}
                            className={`status_options heading__filter`}>
                        <option value={'all'}>{texts.all}</option>
                        {productNames.map(pn=><option key={encodeURIComponent(pn.name)} value={encodeURIComponent(pn.name)}>{pn.name}</option>)}

                    </select>
                </div> : ""}
                {/*status filter*/}
                {user?.accessLevel > 3 ? <div className="navLink filters__container">
                    <label htmlFor={"status"} className="filter__label">{texts.status}:</label>
                    <select name="status"
                            onChange={(e) => assignStatusFilter(e.target.value)}
                            defaultValue={filter.creationProgress}
                            className={`status_options heading__filter`}>
                        <option value={'all'}>{texts.all}</option>
                        <option className="None_option" value={"None"}>{texts.newText}</option>
                        <option className="designer_option" value={"designer"}>{texts.designerRole}</option>
                        <option className="printCenter_option" value={"printCenter"}>{texts.printCenterRole}</option>
                        <option className="ready_option" value={"ready"}>{texts.ready}</option>
                        <option className="atoBeSpecified_option"
                                value={"atoBeSpecified"}>{texts.toBeSpecified}</option>
                        <option className="painting_option" value={"painting"}>{texts.painting}</option>
                        <option className="finished_option" value={"finished"}>{texts.finished}</option>
                    </select>
                </div> : ""}

                {/*people filter*/}
                {user?.accessLevel > 7 ? <div className="navLink filters__container">
                    <label htmlFor={"users"} className="filter__label">{texts.show}:</label>
                    <select name="users"
                            onChange={(e) => assignFilter(e.target.value)}
                            className="status_options heading__filter"
                            value={filter.id}>
                        <option value={'all'}>{texts.all}</option>
                        <optgroup label={texts.designers}>
                            {users?.filter(u => u.designer && !u.suspended).map((designer) => {
                                return <option key={uuid()} value={designer.designer._id}>{designer.nickname}</option>
                            })}
                        </optgroup>
                        <optgroup label={texts.artists}>
                            {users?.filter(u => u.artist && !u.suspended).map((artist) => {
                                return <option key={uuid()} value={artist.artist._id}>{artist.nickname}</option>
                            })}
                        </optgroup>
                    </select>
                </div> : ""}

                {/*NB filter*/}
                {user?.accessLevel > 7 ? <div className="navLink filters__container">
                    <label htmlFor={"priority"} className="filter__label">{texts.priority}</label>
                    <select name="priority"
                            onChange={(e) => assignPriorityFilter(e.target.value)}
                            defaultValue={filter}
                            className={`status_options heading__filter`}>
                        <option value={'all'}>{texts.all}</option>
                        <option className="emergency_option" value={"emergency"}>{texts.emergency}</option>
                        <option className="priority_option" value={"priority"}>{texts.important}</option>
                    </select>
                </div> : ""}
            </>}

            <a style={{cursor: "pointer"}} onClick={() => setShowFilters(!showFilters)}> <Image
                src={'filters.png'}/></a>
            <div className="logoutUser__container">
                <span className="user__nickname">{user?.nickname || ""}</span>
                {user ? <Button
                    onClick={logoutUser}
                    style={logOutBtnStyle}
                    title={texts.logout}
                > </Button> : ""}
            </div>
        </div>
    )
};

export default Heading;