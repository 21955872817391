// components
import OrdersList from "../components/OrdersList";

// icons
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowUp} from '@fortawesome/free-solid-svg-icons'
import {useOrdersContext} from "../context/orders/context";
import {useUserContext} from "../context/user/context";
import {useEffect, useState} from "react";
import Button from "../components/Partials/Button/Button";
import texts from "../config/translationsConfig";
import './Fulfilled.css'

const Fulfilled = () => {
    const scrollToTop = () => {
        document.body.scrollTo({top: 0, left: 0, behavior: "smooth"})
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }
    const {orders, getOrders, handleChange, filter} = useOrdersContext();
    const {users, user, getUsers} = useUserContext();
    const initialOrdersToLoad = 50;

    const [ordersToLoad, setOrdersToLoad] = useState(initialOrdersToLoad)


    const loadMoreOrders = () => {
        setOrdersToLoad(ordersToLoad + initialOrdersToLoad)
    }


    useEffect(() => {
        if (user?.accessLevel === 6) {
            let filterObj = {
                type: 'designer',
                id: user.designer,
                creationProgress: 'all',
                store: 'all',
                stores: ['dog-hero', 'dog-land', 'custom-pet']
            }
            handleChange({name: 'filter', value: filterObj});
        } else if (user?.accessLevel === 2) {
            let filterObj = {
                type: 'artist',
                id: user.artist,
                creationProgress: 'all',
                store: 'all',
                stores: ['dog-hero', 'dog-land', 'custom-pet']
            }
            handleChange({name: 'filter', value: filterObj});
        }
    }, [user])

    useEffect(() => {
        getOrders('fulfilled', ordersToLoad / 2);
        getUsers();
    }, [filter, ordersToLoad])


    return (
        <div className="home__container">
            <button onClick={scrollToTop}
                    style={{
                        background: "#359df7",
                        position: "fixed",
                        bottom: "35px",
                        right: "35px",
                        borderRadius: "7px",
                        zIndex: "10"
                    }}><FontAwesomeIcon
                icon={faArrowUp} size="3x"/></button>
            <br/>
            <OrdersList orders={orders} users={users} user={user}/>
            {ordersToLoad <= orders.length + initialOrdersToLoad ?
                <div className={'load__more__btn'}><Button onClick={loadMoreOrders} title={texts.loadMore}
                                                           className={'load__more__btn__style'}/></div> : ''}
        </div>
    )
}

export default Fulfilled;