import React, {useState} from "react";

import texts from "../../config/translationsConfig";
import DatePicker, {setDefaultLocale} from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Deadline.css'
import {serverFetch} from "../../config/axiosConfig";

const Deadline = ({order, printCenter}) => {

    const [startDate, setStartDate] = useState(new Date(order.deadline));
    const [editDate, setEditDate] = useState(false);

    const updateDate = async (date) => {
        try {
            setStartDate(date);
            setEditDate(false);

            const response = await serverFetch(`/updateOrder`, {
                method: "post",
                data: {
                    store: order.store,
                    query: {_id: order._id},
                    update: {deadline: date.getTime()}
                }
            })
        } catch (e) {
            console.log('date not changed')
        }
    }

    return (
        <div
            className={`orderRow orderTable_deadline dropdown__clickable ${startDate < Date.now() ? 'deadline_red_color' : ''} ${printCenter ? 'cursor_none' : ''}`}>
            {editDate && !printCenter ?
                <DatePicker className={'order__date_picker'} selected={startDate} dateFormat="dd/MM/yyyy"
                            onChange={(date) => updateDate(date)}/>
                : <div title={printCenter ? '' : texts.editDeadline}
                       onClick={() => setEditDate(!editDate)}>{new Date(startDate).toLocaleDateString("en-UK")}</div>}
        </div>
    )
}

export default Deadline;