// bulgarian
const texts = {
    adminPanel: "Админ",
    createNewUser: "Създаване на нов потребител",
    nickname: "прякор",
    selectValue: "изберете роля",
    adminRole: "Администратор",
    customerSupportRole: "Обслужване на клиенти",
    designerRole: "Дизайнер",
    artistRole: "Художник",
    printCenterRole: "Принт център",
    printCenterAndOperator: "Оператори/Принт",
    operator: "Оператор",
    createNewUserBtnText: "Създаване",
    newUserCreated: "Потребителят е създаден успешно!",
    userExists: "Потребител с такава ел. поща вече съществува!",
    emailRequired: "Моля, попълнете ел. поща!",
    emailNotValid: "Ел. поща не е валидна!",
    passwordRequired: "Моля, попълнете парола!",
    nicknameRequired: "Моля, попълнете прякор!",
    roleRequired: "Моля, изберете роля!",
    createNewOrder: "Създаване на нова поръчка",
    artistInfo: "Месечни справки",
    reports: "Отчети",
    editUsers: "Регистрирани потребители",
    areYouSure: "Сигурни ли сте, че искате да изтриете ",
    yes: "ДА!",
    no: "НЕ!",
    fulfilledOrders: "Приключени",
    register: "Регистрация",
    login: "Вход",
    email: "ел. поща",
    emailOrNickname: "ел. поща или прякор",
    password: "парола",
    orderNum: "№",
    manualOrderNumber: "Автоматичен номер",
    date: "Дата",
    name: "Име",
    size: "Размер",
    frameColor: "Цвят",
    productName: "Продукт",
    count: "Брой",
    price: "Цена",
    refunded: "Refund",
    address: "Адрес",
    deadline: "Срок",
    status: "Статус",
    artist: "Художник",
    trackingNum: "Тракинг",
    sentToPrint: "За Принт",
    notes: "Забележка",
    priority: "!N.B.",
    mark: "Марк.",
    working: "Работи се",
    shippingAddress: "Адрес за доставка",
    images: "Снимки клиент",
    upload: "Добави",
    read: "Прочети",
    designImages: "Снимка дизайнер",
    artistImages: "Снимка художник",
    changeStatus: "Промени",
    delete: "Изтрий",
    suspend: "Спри",
    start: "Пусни",
    confirm: "Потвърди",
    ready: "За вземане",
    painting: "Рисува се",
    dogHero: "Dog Hero",
    dogLand: "Dog Len",
    customPet: "Custom Pet",
    logout: "Изход",
    accessDenied: "В момента сървърът не може да отговори на заявката Ви. Моля, опитайте по-късно!",
    startPainting: "Взета",
    paintingDeadline: "Срок",
    money: "Сума",
    artistStatus: "Плащане",
    finished: "Готова",
    payment: "Плащане",
    chooseArtist: "Изберете художник",
    show: "Покажи",
    store: "Магазин",
    orderCount: "Брой продукти: ",
    all: "Всички",
    designers: "Дизайнери",
    artists: "Художници",
    artistsAndCount: "Художници / 'Рисува се'- бр.",
    emergency: "Спешни",
    important: "Важни",
    searchInput: "поща или телефон",
    paid: "Платена",
    notPaid: "Не платена",
    notAccess: "Нямате достъп до тази страница!",
    unknownName: "Непознато име",
    addNote: "Добави забележка",
    changeNote: "Промени забележка",
    picture: "снимката",
    close: "Затвори",
    pdf: "Изтегли PDF",
    nextImage: "Следваща",
    previousImage: "Предишна",
    download: "Изтегли",
    openInNewTab: "Отвори в нов таб",
    newText: "Необработени",
    fulfilled: "Приключена",
    toBeSpecified: "Уточнява се",
    paymentSum: "Сума",
    seeProgressHistory: "Виж статус историята",
    copy: "Копирай",
    editDeadline: "Редактирай краен срок",
    editPrice: "Редактирай цена",
    editSize: "Редактирай размер",
    editNote: "Редактирай бележка",
    loadMore: "Зареди още",
    countOfPayment: "Брой неплатени продукти: ",
    allPaymentSum: "Обща сума за плащане: ",
    payAll: "Плати Всички!",
    picsAndAddresses: "Изтегли снимки на дизайнер!",
    unMarkAll: "Размаркирай всички продукти!",
    areYouSurePayAll: "Сигурни ли сте, че искате да маркирате ВСИЧКИ продукти за ПЛАТЕНИ",
    areYouSurePicsAndAddresses: "Искате ли да принтирате данните на немаркираните ПОРЪЧКИ",
    areYouSureUnMarkAll: "Сигурни ли сте, че искате да размаркирате всички продукти",
    selectStore: "Изберете магазин",
    noFrame: "Без рамка",
    customerName: "Име на клиента",
    customerEmail: "Поща на клиента",
    city: "Град",
    stateCode: "Код на Щата",
    zipCode: "Пощенски код",
    country: "Държава",
    phone: "Телефон",
    frame: "Рамка",
    orderCreated: "Поръчката е създадена успешно!",
    orderNotCreated: "Поръчката не бе записана, моля опитайте по-късно!",
    chooseStore: "Моля, попълнете всички задължителни полета!",
    reportDownloaderHeading: "Генериране на репорт за избран период",
    artistInfoHeading: "Справка за нарисувани продукти",
    chooseStartDate: "Моля, изберете начална дата: ",
    chooseFinalDate: "Моля, изберете крайна дата: ",
    chooseReportStore: "Моля, изберете магазин: ",
    allFrames: "Общо: ",
    clientNote: "Коментар от клиента",
    appText: "PAW - Изтеглете или актуализирайте"
}

export default texts;