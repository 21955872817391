import texts from "../../config/translationsConfig";
import {useEffect, useState} from "react";
import './Deadline.css'
import {serverFetch} from "../../config/axiosConfig";
import {useFirstRender} from "../../utils/functionUtils";

const Size = ({order, printCenter}) => {

    const [size, setSize] = useState(order.portraitSize?.toString() || '----');
    const [debouncedSize, setDebouncedSize] = useState(size);
    const [editSize, setEditSize] = useState(false);

    const updateSize = async (newSize) => {
        try {
            const response = await serverFetch(`/updateOrder`, {
                method: "post",
                data: {
                    store: order.store,
                    query: {_id: order._id},
                    update: {portraitSize: newSize}
                }
            })
        } catch (e) {
            console.log('price not changed')
        }
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedSize(size);
        }, 1500);

        return () => {
            clearTimeout(timerId);
        };
    }, [size]);

    const firstRender = useFirstRender();

    useEffect(() => {
        if (!firstRender) {
            setEditSize(false);
            updateSize(size)
        }
    }, [debouncedSize]);

    return (
        <div className={`orderRow orderTable_price dropdown__clickable ${printCenter ? 'cursor_none' : ''}`}>
            {editSize && !printCenter ?
                <input value={size} onChange={(e) => setSize(e.target.value)} className="edit_price_input"/> :
                <div title={printCenter ? '' : texts.editSize} onClick={() => setEditSize(!editSize)}>{size}</div>}
        </div>
    )
}

export default Size;