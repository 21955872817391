import {
    GET_ORDERS_BEGIN,
    GET_ORDERS_SUCCESS,
    GET_ORDERS_ERROR,
    SET_ORDERS,
    HANDLE_CHANGE, SET_LEADERBOARD,
    UPDATE_ORDER_BEGIN,
    UPDATE_ORDER_SUCCESS,
    UPDATE_ORDER_ERROR,
    UPLOAD_IMAGE_ERROR,
    UPLOAD_IMAGE_END,
    DELETE_IMAGE_END,
    DELETE_IMAGE_ERROR,
    GET_PRODNAMES_SUCCESS

} from './actions';

const reducer = (state, action) => {

    if (action.type === GET_ORDERS_BEGIN) {
        return {
            ...state,
            isLoading: true
        }
    }

    if (action.type === GET_ORDERS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            orders: action.payload.orders,
        }
    }

    if (action.type === GET_PRODNAMES_SUCCESS) {
        return {
            ...state,
            productNames: action.payload.productNames,
        }
    }

    if (action.type === GET_ORDERS_ERROR) {
        return {
            ...state,
            isLoading: false,
            // showAlert: true,
            // alertType: 'danger',
            // alertText: action.payload.msg
        }
    }

    if (action.type === SET_ORDERS) {
        return {
            ...state,
            orders: action.payload.orders,
        }
    }

    if (action.type === HANDLE_CHANGE) {
        return {
            ...state,
            [action.payload.name]: action.payload.value
        }
    }

    if (action.type === SET_LEADERBOARD) {
        return {
            ...state,
            leaderboard: action.payload.leaderboard
        }
    }

    if (action.type === UPDATE_ORDER_BEGIN) {
        return {
            ...state,
            isLoading: true
        }
    }

    if (action.type === UPDATE_ORDER_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            orders: action.payload.orders,
        }
    }

    if (action.type === UPDATE_ORDER_ERROR) {
        return {
            ...state,
            isLoading: false,
            // showAlert: true,
            // alertType: 'danger',
            // alertText: action.payload.msg
        }
    }
    if (action.type === UPLOAD_IMAGE_ERROR) {
        return {
            ...state,
            isLoading: false,
            // showAlert: true,
            // alertType: 'danger',
            // alertText: action.payload.msg
        }
    }
    if (action.type === UPLOAD_IMAGE_END) {
        return {
            ...state,
            orderStatus: action.payload.order,
            isLoading: false,
            // showAlert: true,
            // alertType: 'danger',
            // alertText: action.payload.msg
        }
    }

    if (action.type === DELETE_IMAGE_ERROR) {
        return {
            ...state,
            isLoading: false,
            // showAlert: true,
            // alertType: 'danger',
            // alertText: action.payload.msg
        }
    }
    if (action.type === DELETE_IMAGE_END) {
        return {
            ...state,
            isLoading: false,
            // showAlert: true,
            // alertType: 'danger',
            // alertText: action.payload.msg
        }
    }

    throw new Error(`no such action : ${action.type}`);
}

export default reducer;