import uuid from "react-uuid";

const CreateOrderSelect = ({options, className, setValue, value, heading, disabled, required}) => {
    return (
        <div>
            <div className={'input_heading'}>{heading} :</div>
            <select onChange={(e) => setValue(e.target.value)} className={className}
                    value={value} required={required}>
                {disabled ? <option key={uuid()} value={disabled.value} disabled>{disabled.text}</option> : ""}
                {options.map((o) => {
                    return <option key={uuid()} value={o.value}>{o.text}</option>
                })}
            </select>
        </div>
    )
};

export default CreateOrderSelect;