import {useState} from "react";

const DesignerWorkingCheck = ({order, updateOrder, propToChange}) => {
    const [designerWorking, setDesignerWorking] = useState(order[propToChange]);

    const changeWorkingStatus = () => {
        setDesignerWorking(!designerWorking)
        updateOrder({_id: order._id}, {[propToChange]: !designerWorking})
    }


    return (
        <div className={designerWorking ? "designerWorking_background" : ""}>
             <span>
                    <input className="dropdown__clickable__emergency" onChange={changeWorkingStatus} type="checkbox"
                           checked={`${designerWorking ? "checked" : ""}`}/>
                    </span>
        </div>
    )
}

export default DesignerWorkingCheck;