import {useState} from "react";
import {serverFetch} from "../../config/axiosConfig";
import {useUserContext} from "../../context/user/context"
import {useAppContext} from "../../context/appContext";

const PaymentCheckBox = ({
                             initialCheck,
                             setNewStatus,
                             query,
                             updateProp,
                             updateForTrue,
                             artistPrice,
                             order
                         }) => {

    const [checked, setChecked] = useState(initialCheck);
    const {paymentSum, handleChange} = useAppContext();
    const {user} = useUserContext();

    const updateOrder = async (query, update) => {
        const response = await serverFetch(`/updateOrder`, {
            method: "post",
            data: {store: order.store, query, update}
        })
    }

    const changeRefunded = () => {
        setChecked(!checked);


        if (updateProp === 'artistPaid') {
            if (checked) setNewStatus(false);
            else setNewStatus(!checked);

            let newPaymentSum = paymentSum;
            if (!checked) newPaymentSum += artistPrice;
            else if (checked) newPaymentSum -= artistPrice;
            handleChange({name: 'paymentSum', value: newPaymentSum});
        } else {
            if (!checked) {
                setNewStatus(true);
                updateOrder(query, updateForTrue);
                updateOrder(query, {
                    $push: {
                        progressHistory: {
                            date: Date.now(),
                            user: user.nickname,
                            status: 'fulfilled'
                        }
                    }
                })
            } else {
                setNewStatus(!checked);
            }
        }

        updateOrder(query, {[updateProp]: !checked});
    }

    return (
        <input className={'payment__checkbox'}
               onChange={changeRefunded} type="checkbox"
               checked={checked}
        />
    )
}

export default PaymentCheckBox;