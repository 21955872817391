import Button from "../Partials/Button/Button";
import texts from "../../config/translationsConfig";
import {serverFetch} from "../../config/axiosConfig";
import React, {useState, useRef} from "react";
import ReactToPrint from 'react-to-print';
import ComponentToPrint from './ComponentToPrint';

import './DownloadAll.css'

const DownloadAll = ({orders}) => {
    const componentRef = React.createRef();

    // should download images of ALL Orders(all products)
    const ordersToDownload = orders.filter(o => o.downloaded === false
        && o.creationProgress === 'printCenter');

    // should print only orders (without second and third products)
    const ordersToPrint = ordersToDownload.filter(o => o.orderNumber.indexOf('-') === -1);

    const [openModal, setOpenModal] = useState(false);

    const downloadAllUnmarked = async () => {
        setOpenModal(true);
        ordersToDownload.map(async (o) => {
            await downloadImages(o);
        });
    }

    const confirmDelete = async () => {
        try {
            setOpenModal(false);
            await downloadAllUnmarked();

        } catch (e) {
        }


    }

    const updateOrder = async (order, query, update) => {
        try {
            const response = await serverFetch(`/updateOrder`, {
                method: "post",
                data: {store: order.store, query, update}
            })
            console.log('response: ', response)
        } catch (e) {
            console.log(e)
        }
    }

    const downloadImages = async (order) => {
        try {
            let currentImageUrl = order.designImages[0];
            if (currentImageUrl) {
                let b64 = await serverFetch(`/viewImage?imageUrl=${currentImageUrl}&&store=${order.store}&&date=${order.orderDate}`)
                if (b64.data.base64) {
                    //console.log('base: ', b64.data.base64)
                    let urlExt = currentImageUrl?.substring(currentImageUrl.length - 6, currentImageUrl.length);

                    let fileExt;
                    if (urlExt?.toLowerCase().indexOf('heic') !== -1 || urlExt.indexOf('heif') !== -1) fileExt = 'png';
                    else fileExt = urlExt?.toLowerCase().split('.')[1];


                    let link = document.createElement('a');
                    link.href = b64.data.base64;
                    link.setAttribute('download', `${order.orderNumber ? order.orderNumber : 'petHero'}.${fileExt}`);
                    document.body.appendChild(link);
                    link.click();
                    await updateOrder(order, {_id: order._id}, {downloaded: true})
                }
            }

        } catch (e) {
            console.log(e)
        }
    }

    const downloadPdfs = async (order) => {
        try {
            let resp = await serverFetch(`/downloadPdf?orderId=${order._id}`, {responseType: 'arraybuffer'});
            const url = await window.URL.createObjectURL(new Blob([resp.data], {type: "application/pdf"}))
            let link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${order.orderNumber ? order.orderNumber : 'petHero'}.pdf`);
            document.body.appendChild(link);
            link.click();
            await updateOrder(order, {_id: order._id}, {downloaded: true})
        } catch (e) {
            console.log(e)
        }
    }

    return (
        <div className={'download_all_container'}>
            {!openModal ?
                <Button className={'pay_all_button'} title={texts.picsAndAddresses} onClick={downloadAllUnmarked}/>
                : <div className="confirm_delete_modal">
                    <div className="pay_all_text">{texts.areYouSurePicsAndAddresses}?</div>
                    <div className="modal_btns">
                        <ReactToPrint content={() => componentRef.current} trigger={() =>
                            <Button title={texts.yes}
                                    className={`edit_btn normal`}/>
                        }/>
                        <Button onClick={() => setOpenModal(false)} title={texts.close}
                                className={`delete_btn normal`}/>
                    </div>
                </div>}

            <div style={{display: 'none'}}>
                <ComponentToPrint ref={componentRef} orders={ordersToPrint}/>
            </div>


        </div>
    )
};

export default DownloadAll;