import {
    SETUP_USER_BEGIN,
    UPDATE_USER_BEGIN,
    SETUP_USER_SUCCESS,
    UPDATE_USER_SUCCESS,
    SETUP_USER_ERROR,
    UPDATE_USER_ERROR,
    GET_USERS_BEGIN,
    GET_USERS_ERROR,
    GET_USERS_SUCCESS,
} from './actions';

import {initialState} from './context';
import {GET_ORDERS_BEGIN, GET_ORDERS_ERROR, GET_ORDERS_SUCCESS} from "../orders/actions";

const reducer = (state, action) => {
    if (action.type === SETUP_USER_BEGIN) {
        return {
            ...state,
            isLoading: true
        }
    }

    if (action.type === SETUP_USER_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            user: action.payload.user,
        }
    }

    if (action.type === SETUP_USER_ERROR) {
        return {
            ...state,
            isLoading: false,
            // showAlert: true,
            // alertType: 'danger',
            // alertText: action.payload.msg
        }
    }

    if (action.type === UPDATE_USER_BEGIN) {
        return {
            ...state,
            isLoading: true,
        }
    }

    if (action.type === UPDATE_USER_SUCCESS) {
        return {
            ...state,
            isLoading: false,
        }
    }

    if (action.type === UPDATE_USER_ERROR) {
        return {
            ...state,
            isLoading: false,
            // showAlert: true,
            // alertType: 'danger',
            // alertText: action.payload.msg
        }
    }

    if (action.type === GET_USERS_BEGIN) {
        return {
            ...state,
            isLoading: true
        }
    }

    if (action.type === GET_USERS_SUCCESS) {
        return {
            ...state,
            isLoading: false,
            users: action.payload.users,
        }
    }

    if (action.type === GET_USERS_ERROR) {
        return {
            ...state,
            isLoading: false,
            // showAlert: true,
            // alertType: 'danger',
            // alertText: action.payload.msg
        }
    }

    throw new Error(`no such action : ${action.type}`);
}

export default reducer;