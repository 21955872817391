// components
import OrdersList from "../components/OrdersList";

// icons
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowUp} from '@fortawesome/free-solid-svg-icons'
import {useEffect} from "react";
import {useOrdersContext} from "../context/orders/context";
import {useUserContext} from "../context/user/context";
import texts from "../config/translationsConfig";
import Button from "../components/Partials/Button/Button";
import {serverFetch} from "../config/axiosConfig";
import './Payment.css';
import PayAll from "../components/Payment/PayAll";

const Payment = () => {
    const scrollToTop = () => {
        document.body.scrollTo({top: 0, left: 0, behavior: "smooth"})
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    const {orders, getOrders} = useOrdersContext();
    const {getUsers, users, user} = useUserContext();

    const countSum = () => {
        let sum = 0;
        orders.map((o) => sum += o.artistPrice)
        return sum;
    }

    useEffect(() => {
        getOrders('payment')
        getUsers();
    }, [])
    return (
        user?.accessLevel > 7 ?

            <div className="home__container">
                <button onClick={scrollToTop}
                        style={{
                            background: "#359df7",
                            position: "fixed",
                            bottom: "35px",
                            right: "35px",
                            borderRadius: "7px",
                            zIndex: "10"
                        }}><FontAwesomeIcon
                    icon={faArrowUp} size="3x"/></button>
                <br/>
                <OrdersList orders={orders} users={users} user={user}/>
                <br/>
                <div className="payment__statistics_container">
                    <div>
                        <span>{texts.countOfPayment}</span>
                        <span>{orders.length}</span>
                    </div>
                    <div>
                        <span>{texts.allPaymentSum}</span>
                        <span>{countSum()}</span>
                    </div>
                    <PayAll orders={orders}/>
                </div>
            </div> : ''
    )
}

export default Payment;