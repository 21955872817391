import React, {useEffect, useState, memo} from "react";

//translations
import texts from "../config/translationsConfig";

// style
import './OrderDropdown.css';

// components
import Button from "./Partials/Button/Button";
import ImageItem from "./Images/ImageItem";
import ImageUploader from "./Images/ImageUploader";

import {useUserContext} from "../context/user/context";
import {
    useFirstRender,
    copyStringToClipboard,
    formatSize,
    formatFrameColor,
    formatProduct
} from "../utils/functionUtils";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faTrash} from '@fortawesome/free-solid-svg-icons'
import {faCopy} from '@fortawesome/free-solid-svg-icons'
import uuid from "react-uuid";
import NoteInput from "./NoteInput";
import {serverFetch} from "../config/axiosConfig";
import CheckBox from "./Dropdown/CheckBox";
import ProgressHistory from './Dropdown/ProgressHistory'
import Image from "./Partials/Image/Image";
import {useOrdersContext} from "../context/orders/context";


const OrderDropdown = memo(({
                                order,
                                orderIndex,
                                artistPrice,
                                setArtistPrice,
                                artistSelected,
                                orderRefunded,
                                setOrderRefunded,
                                setCreationProgress,
                                notesFor
                            }) => {
    const [newTracking, setNewTracking] = useState(order.trackingNumber);
    const [debouncedTracking, setDebouncedTracking] = useState(newTracking);
    const [editTracking, setEditTracking] = useState(false);
    const [newImages, setNewImages] = useState(order.images);
    const [newArtistPhotos, setNewArtistPhotos] = useState(order.artistPhotos);
    const [newDesignImages, setNewDesignImages] = useState(order.designImages);
    const [notes, setNotes] = useState(order.notes);
    const [refundCheck, setRefundCheck] = useState(orderRefunded || order.refunded);
    const [newImageDeleted, setNewImageDeleted] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingDesigner, setIsLoadingDesigner] = useState(false);
    const [isLoadingArtist, setIsLoadingArtist] = useState(false);
    const {productNames} = useOrdersContext()
    const {user} = useUserContext();

    const updateOrder = async (query, update) => {
        try {
            const response = await serverFetch(`/updateOrder`, {
                method: "post",
                data: {store: order.store, query, update}
            })
        } catch (e) {
            console.log(e)
        }
    }

    const updateArtistPrice = (newPrice) => {
        setArtistPrice(Number(newPrice));
        updateOrder({_id: order._id}, {artistPrice: newPrice})
    }

    const updateTracking = (newTracking) => {
        setNewTracking(newTracking);
    }

    const deleteNote = (index) => {
        let newNotes = [...notes]
        newNotes.splice(index, 1);
        setNotes(newNotes)
        updateOrder({_id: order._id}, {notes: newNotes});
    }

    const copyAddress = () => {
        let formatted;
        if (order.orderNumber.length === 4) formatted = `${order.store === 'dog-land' ? 'ST ' : ''}Order ${order.orderNumber}\n\n${order?.shippingAddress?.name}\n${order?.shippingAddress?.address1} ${order.shippingAddress?.address2}\n${order?.shippingAddress?.city} ${order?.shippingAddress?.province_code} ${order?.shippingAddress?.zip}\n${order.shippingAddress?.country}\n${order?.shippingAddress?.phone}\n\nSize: ${formatSize(order.orderNumber, order.portraitSize)} Color: ${formatFrameColor(order, order.portraitSize)}\nProduct: ${formatProduct(order.orderNumber, order.productName)}\n\n`
        else formatted = `Size: ${formatSize(order.orderNumber, order.portraitSize)} Color: ${formatFrameColor(order, order.portraitSize)}\nProduct: ${formatProduct(order.orderNumber, order.productName, productNames)}`;
        copyStringToClipboard(formatted)
    }

    const sendImageToServer = (e, imageType, order) => {
        try {
            for (let i = 0; i < e.target.files.length; i++) {
                let file = e.target.files[i];

                let reader = new FileReader();
                reader.readAsDataURL(file);

                reader.onload = async () => {
                    let response;
                    let base64 = reader.result;
                    let isPdf = base64.split(';base64')[0].indexOf('pdf') !== -1;
                    if (isPdf) {
                        response = await serverFetch(`/uploadPdf`, {
                            method: "post",
                            data: {base64, order}
                        })
                    } else {
                        response = await serverFetch(`/uploadImage`, {
                            method: "post",
                            data: {base64, imageType, order}
                        })
                    }

                    setIsLoading(false);
                    setIsLoadingDesigner(false);
                    setIsLoadingArtist(false);

                    if (imageType === 'images') {
                        let upToDate = [...newImages];
                        upToDate.push(response.data.imageUrl);
                        setNewImages(upToDate);
                    } else if (imageType === 'designImages') {
                        let upToDate = [response.data.imageUrl];
                        setNewDesignImages(upToDate);
                    } else if (imageType === 'artistPhotos') {
                        let upToDate = [response.data.imageUrl];
                        setNewArtistPhotos(upToDate);
                    }
                }
            }
        } catch (e) {
            setIsLoading(false);
            setIsLoadingDesigner(false);
            setIsLoadingArtist(false);
        }
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedTracking(newTracking);
        }, 1000);

        return () => {
            clearTimeout(timerId);
        };
    }, [newTracking]);

    const firstRender = useFirstRender();

    useEffect(() => {
        if (!firstRender) updateOrder({_id: order._id}, {
            trackingNumber: debouncedTracking
        })
    }, [debouncedTracking]);

    useEffect(() => {
        if (newImages && newImageDeleted) {
            if (newImageDeleted.type === 'images') {
                let upToDate = newImages.filter(i => i !== newImageDeleted.imageUrl)
                setNewImages(upToDate)
            } else if (newImageDeleted.type === 'designImages') {
                let upToDate = newDesignImages.filter(i => i !== newImageDeleted.imageUrl)
                setNewDesignImages(upToDate)
            } else if (newImageDeleted.type === 'artistPhotos') {
                let upToDate = newArtistPhotos.filter(i => i !== newImageDeleted.imageUrl)
                setNewArtistPhotos(upToDate)
            }
        }
    }, [newImageDeleted]);

    return (
        <div className="dropdown__container">
            <div>
                <strong style={{color: "cyan"}}>Full Product Name:</strong> {order.productName}
                {order.props.length === 0 ? " ||  No Props" :
                    <div style={{border: '1px solid orange'}}>{order.props.map(prp => (<><strong
                        style={{color: "orange"}}>{prp.name}</strong>: <u>{prp.value}</u></>))}</div>}
            </div>
            {user?.accessLevel > 2 ?
                <div className="dropdown__col dropdown__address">

                    {order?.note ?
                        <>
                            <div className="dropdown__heading note_heading">{texts.clientNote}:</div>
                            <div>{order?.note}</div>
                        </>
                        : ''}
                    {order?.shippingAddress ? <>

                        <div className="dropdown__heading">{texts.shippingAddress}:</div>
                        <div>Order {order.orderNumber}
                            <span><FontAwesomeIcon className="dropdown__copy_icon" title={texts.copy}
                                                   icon={faCopy} size="2x"
                                                   onClick={copyAddress}/></span></div>
                        <div>{order?.shippingAddress?.name}
                            <span><FontAwesomeIcon className="dropdown__copy_icon_small"
                                                   icon={faCopy}
                                                   onClick={() => copyStringToClipboard(order?.shippingAddress?.name)}/></span>
                            {/* - <span*/}
                            {/*className="dropdown_username">{order.customer.orders.find(o => o.artistId)?.artistId.userId.nickname}</span>*/}
                        </div>
                        {user?.accessLevel > 7 ?
                            <div>{order.customer.phone}{order.customer.phone !== '' ? <span><FontAwesomeIcon
                                className="dropdown__copy_icon_small" title={texts.copy}
                                icon={faCopy}
                                onClick={() => copyStringToClipboard(order.customer.phone)}/></span> : ''}</div> : ''}
                        {user?.accessLevel > 7 ?
                            <div>{order.customer.email}{order.customer.email !== '' ?
                                <span><FontAwesomeIcon className="dropdown__copy_icon_small"
                                                       title={texts.copy}
                                                       icon={faCopy}
                                                       onClick={() => copyStringToClipboard(order.customer.email)}/></span> : ''}</div> : ''}
                        {user?.accessLevel > 7 ? <div className="dropdown__refunded">
                            <CheckBox
                                titleClassName={'artist__prize__heading dropdown__refund__heading'}
                                titleText={texts.refunded}
                                inputClassName={'dropdown__clickable dropdown__clickable__payment'}
                                initialCheck={refundCheck}
                                setNewStatus={setOrderRefunded}
                                query={{_id: order._id}}
                                updateProp={'refunded'}
                                updateForTrue={{creationProgress: 'fulfilled'}}
                                order={order}
                            />
                        </div> : ''}
                        <div>{order?.shippingAddress?.address1} {order?.shippingAddress?.address2}<span><FontAwesomeIcon
                            className="dropdown__copy_icon_small"
                            icon={faCopy}
                            onClick={() => copyStringToClipboard(`${order?.shippingAddress?.address1} ${order?.shippingAddress?.address2 || ''}`)}/></span>
                        </div>
                        <div>{`${order?.shippingAddress?.city} ${order?.shippingAddress?.province_code} ${order?.shippingAddress?.zip}`}
                            <span><FontAwesomeIcon className="dropdown__copy_icon_small"
                                                   icon={faCopy}
                                                   onClick={() => copyStringToClipboard(`${order?.shippingAddress?.zip}`)}/></span>
                        </div>
                        <div>{order?.shippingAddress?.country}<span><FontAwesomeIcon
                            className="dropdown__copy_icon_small"
                            icon={faCopy}
                            onClick={() => copyStringToClipboard(order.shippingAddress.country)}/></span>
                        </div>
                        <div>{order?.shippingAddress?.phone}
                            <span><FontAwesomeIcon className="dropdown__copy_icon_small"
                                                   icon={faCopy}
                                                   onClick={() => copyStringToClipboard(order?.shippingAddress?.phone)}/></span>
                        </div>
                        <div className="dropdown__tracking">
                            <div>{texts.trackingNum} :</div>
                            {editTracking ?
                                <div className="editTracking_container"><input
                                    onChange={(e) => updateTracking(e.target.value)}
                                    type="text"
                                    className="tracking__input"
                                    value={newTracking}/>
                                    <div onClick={() => setEditTracking(false)}
                                         className="add_tracking_button">{texts.confirm}</div>
                                </div> : <div className="tracking_container">
                                    <div className="tracking_value">{newTracking}</div>
                                    <div onClick={() => setEditTracking(true)}
                                         className="add_tracking_button">{newTracking ? texts.changeStatus : texts.upload}</div>
                                </div>}
                        </div>
                    </> : ''}
                </div> : ''}
            <div className="dropdown__col">
                <div className="dropdown__heading">
                    <div className="upload__container__heading">{texts.images}:</div>
                    <div className="image_upload">
                        {user?.accessLevel > 7 ?
                            <ImageUploader order={order} imageType={'images'}
                                           sendImageToServer={sendImageToServer}
                                           setIsLoading={setIsLoading}
                            />
                            : ""}
                    </div>
                </div>
                <div className="dropdown__images__container">
                    {!isLoading ? newImages.map((imageUrl) => {
                        return <ImageItem className={"dropdown__image__item"} key={uuid()} imageUrl={imageUrl}
                                          type={'images'} order={order} setNewImageDeleted={setNewImageDeleted}
                        />
                    }) : <Image className={'loading__image__container'} src={'480.gif'}/>}
                </div>
            </div>
            <div className="dropdown__col">
                <div className="dropdown__heading">
                    <div className="upload__container__heading">{texts.designImages}:</div>
                    <div className="image_upload">
                        {user?.accessLevel > 5 ?
                            <ImageUploader order={order} imageType={'designImages'}
                                           sendImageToServer={sendImageToServer}
                                           setIsLoading={setIsLoadingDesigner}


                            />
                            : ""}
                    </div>
                </div>
                <div className="dropdown__images__container">
                    {!isLoadingDesigner ? newDesignImages.map((imageUrl) => {
                        return <ImageItem className={"dropdown__image__item"} key={uuid()} imageUrl={imageUrl}
                                          type={'designImages'} order={order}
                                          setNewImageDeleted={setNewImageDeleted}/>
                    }) : <Image className={'loading__image__container'} src={'480.gif'}/>}</div>
            </div>
            {user.accessLevel !== 4 ? <div className="dropdown__col">
                <div className="dropdown__heading">
                    <div className="upload__container__heading">{texts.artistImages}:</div>
                    <div className="image_upload">
                        {user?.accessLevel === 2 || user?.accessLevel > 7 ?
                            <ImageUploader order={order} imageType={'artistPhotos'}
                                           sendImageToServer={sendImageToServer}
                                           setIsLoading={setIsLoadingArtist}

                            />
                            : ""}
                    </div>
                </div>
                <div className="dropdown__images__container">
                    {!isLoadingArtist ? newArtistPhotos.map((imageUrl) => {
                        return <ImageItem className={"dropdown__image__item"} key={uuid()} imageUrl={imageUrl}
                                          type={'artistPhotos'} order={order}
                                          setNewImageDeleted={setNewImageDeleted}/>
                    }) : <Image className={'loading__image__container'} src={'480.gif'}/>}
                </div>

            </div> : ""}
            <div className={`dropdown__col ${user.accessLevel < 7 ? 'artist_notes' : ''}`}>
                {user?.accessLevel > 7 ?
                    <div className="dropdown__heading">
                        <div className="dropdown_payArtist">
                            <div className="image_upload">
                                {/*{artistSelected || order.artistId ?*/}
                                {/*    <CheckBox*/}
                                {/*        titleClassName={'artist__prize__heading dropdown__refund__heading'}*/}
                                {/*        titleText={texts.payment}*/}
                                {/*        inputClassName={'dropdown__clickable dropdown__clickable__payment'}*/}
                                {/*        initialCheck={artistPaid}*/}
                                {/*        setNewStatus={setArtistPayment}*/}
                                {/*        query={{_id: order._id}}*/}
                                {/*        updateProp={'artistPaid'}*/}
                                {/*        artistPrice={artistPrice}*/}
                                {/*        order={order}*/}
                                {/*    /> : ""}*/}
                            </div>
                        </div>

                        <div className="dropdown__artist__prize">
                            <span className="artist__prize__heading">{texts.price}: </span>
                            <input onChange={(e) => updateArtistPrice(e.target.value)} type="text"
                                   className="order__artist__prize" value={artistPrice}/>
                        </div>
                    </div>
                    : ''
                }
                <div className="tracking_container">
                    {notes?.map((note, index) => {
                            return note.accessLevel === user.accessLevel || user.accessLevel > 7
                            || !note.accessLevel || note.user === user.nickname ?
                                <div key={uuid()} className="note_value">
                                    <span>{new Date(note?.timestamp).toLocaleDateString("en-UK")} </span>
                                    <span className="dropdown_username">{note?.user}</span>
                                    <span> : {note?.text} </span>
                                    {user.accessLevel > 7 ?
                                        <FontAwesomeIcon className="dropdown_delete_note" onClick={() => deleteNote(index)}
                                                         icon={faTrash}/> : ""}</div>
                                : ''
                        }
                    )}
                </div>
                <NoteInput
                    order={order}
                    notes={notes}
                    setNotes={setNotes}
                    notesFor={notesFor}
                />
                <ProgressHistory order={order}/>
            </div>
        </div>
    )
})

export default OrderDropdown;