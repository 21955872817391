import './Order.css'
import './ArtistOrder.css'
import {useState} from "react";
import OrderDropdown from './OrderDropdown'
import texts from "../config/translationsConfig";
import {formatFrameColor} from "../utils/functionUtils";
import {formatSize, formatProduct} from "../utils/functionUtils";
import {serverFetch} from "../config/axiosConfig";
import uuid from "react-uuid";
import {useOrdersContext} from "../context/orders/context";

const ArtistOrder = ({order, orderIndex}) => {

    const [toggle, setToggle] = useState(false);
    const [notesFor, setNotesFor] = useState(order.notesFor);
    const {productNames}=useOrdersContext()

    const updateOrder = async (query, update) => {
        const response = await serverFetch(`/updateOrder`, {
            method: "post",
            data: {store: order.store, query, update}
        })
    }

    const changeNotesFor = (newNotesFor) => {

        setNotesFor(newNotesFor);
        setToggle(true);
        updateOrder({_id: order._id}, {notesFor: newNotesFor})
    }

    const changeToggle = () => {
        setToggle(!toggle)
    }

    const formatStatus = (status) => {
        let formatted = 'неизвестен';
        if (status === 'finished') formatted = texts.finished
        else if (status === 'painting') formatted = texts.painting
        else if (status === 'fulfilled') formatted = texts.fulfilled
        else if (status === 'atoBeSpecified') formatted = texts.toBeSpecified
        return formatted;
    }


    return (
        <div className={`order__container ${(order.orderNumber.indexOf('-') !== -1 || order.orderNumber === '') ? "orderRowNoBorder" : ""}`}>
            <div
                className={`${order.emergency ? "orderRowRed" : order.priority ? "orderRowLightBlue" : ""} order__container__main`}>
                <div className="orderRow artistTable_date dropdown__clickable"
                     onClick={changeToggle}>{new Date(order.startPainting).toLocaleDateString("en-UK")}</div>
                <div className={`orderRow artistTable_num dropdown__clickable
                                    ${order.store === 'dog-hero' && order.orderNumber.length === 4 ? 'heroBackground' : order.store === 'dog-land' && order.orderNumber.length === 4 ? 'landBackground' : order.store === 'custom-pet' && order.orderNumber.length === 4 ? 'poopyBackground' : ''}`}

                     onClick={changeToggle}>{order.orderNumber}</div>
                <div className="orderRow artistTable_size dropdown__clickable"
                     onClick={changeToggle}>{formatSize(order.orderNumber, order.portraitSize)}</div>
                <div className="orderRow artistTable_count dropdown__clickable"
                     onClick={changeToggle}>{order.frameColor !== '' ? order.frameColor : formatFrameColor(order, order.portraitSize)}</div>
                <div
                    className="orderRow artistTable_product dropdown__clickable"
                    onClick={changeToggle}>{formatProduct(order.orderNumber, order.productName,productNames)}</div>
                <div
                    className={`orderRow artistTable_deadline dropdown__clickable ${order.paintingDeadline < Date.now() ? 'deadline_red_color' : ''}`}
                    onClick={changeToggle}>{new Date(order.paintingDeadline).toLocaleDateString("en-UK")}</div>
                <div className="orderRow artistTable_money dropdown__clickable"
                     onClick={changeToggle}>{order.artistPrice}</div>
                <div className="orderRow artistTable_artistStatus">
                    {formatStatus(order.creationProgress)}
                </div>
                <div className="orderRow artistTable_artistStatus">
                    {order.artistPaid ? texts.paid : texts.notPaid}
                </div>
                <div className="orderRow artistTable_notes">
                    <div className={`dropdown__clickable`}
                    >
                        <select onChange={(e) => changeNotesFor(e.target.value)}
                                className={`status_options ${order.notes.length ? 'order_has_note' : ""}`}
                                value={notesFor !== '' ? notesFor : texts.upload}>
                            <option value={null}>{texts.upload}</option>
                            <option value={"all"}>{texts.all}</option>
                            <option value={"designer"}>{texts.designerRole}</option>
                            <option value={"printCenter"}>{texts.printCenterRole}</option>
                            <option value={"artist"}>{texts.artistRole}</option>
                            <option value={"operator"}>{texts.operator}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div className="orderTable__dropdown">
                {toggle ?
                    <OrderDropdown
                        key={uuid()}
                        order={order}
                        orderIndex={orderIndex}
                        notesFor={notesFor}
                    /> : ""}
            </div>
        </div>
    )
}

export default ArtistOrder;