import './Admin.css';
import {useEffect, useState} from "react";

import texts from "../config/translationsConfig";
import Button from "../components/Partials/Button/Button";
import UserRow from "../components/UserRow";
import {serverFetch} from "../config/axiosConfig";
import {useUserContext} from "../context/user/context";
import uuid from "react-uuid";
import {Link} from "react-router-dom";
import {useOrdersContext} from "../context/orders/context";

const Admin = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [nickname, setNickname] = useState("");
    const [accessLevel, setAccessLevel] = useState(1);
    const [message, setMessage] = useState("");
    const [tempProds, setTempProds] = useState({});
    const {user, users} = useUserContext();
    const {getProductNames, productNames, updateProductNickname} = useOrdersContext()

    console.log(productNames)
    useEffect(() => getProductNames(), [])

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const createNewUser = async (event) => {
        event.preventDefault();

        if (!email) {
            setMessage(texts.emailRequired);
            return
        }
        if (!validateEmail(email)) {
            setMessage(texts.emailNotValid);
            return
        }
        if (!password) {
            setMessage(texts.passwordRequired);
            return
        }
        if (!nickname) {
            setMessage(texts.nicknameRequired);
            return
        }
        if (accessLevel === 1) {
            setMessage(texts.roleRequired);
            return
        }


        let response = await serverFetch.post('/createNewUser', {email, password, nickname, accessLevel});

        if (response.data._id) {
            setMessage(texts.newUserCreated)
            setEmail('');
            setPassword('');
            setNickname('');
            setTimeout(() => setMessage(''), 3000);
            window.location.reload();
        } else if (response.data.msg === 'user exists') setMessage(texts.userExists);
    }

    return (

        user?.accessLevel > 7 ?
            <div className="adminContainer">
                <div className="createUser_container">
                    <h2 className="newUserHeading">{texts.createNewUser} :</h2>
                    <form>
                        <input
                            placeholder={texts.email}
                            className="mail__input createUserMail"
                            type="text"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}/>
                        <input
                            placeholder={texts.password}
                            className="inline__input"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}/>
                        <input
                            placeholder={texts.nickname}
                            className="mail__input createUser_nickname"
                            type="text"
                            value={nickname}
                            onChange={(e) => setNickname(e.target.value)}/>
                        <select onChange={(e) => setAccessLevel(e.target.value)} className="createUser_options"
                                defaultValue={texts.selectValue}>
                            <option value={texts.selectValue} disabled>{texts.selectValue}</option>
                            <option value={10}>{texts.adminRole}</option>
                            <option value={9}>{texts.customerSupportRole}</option>
                            <option value={6}>{texts.designerRole}</option>
                            <option value={4}>{texts.printCenterRole}</option>
                            <option value={2}>{texts.artistRole}</option>
                        </select>
                        <Button
                            className="button__form createUserBtn"
                            title={texts.createNewUserBtnText}
                            onClick={createNewUser}
                        />
                        <h5>{message}</h5>
                    </form>
                </div>
                {user.accessLevel > 7 ? <div className="createOrder_container">
                    <Link className={'admin_pageLink'} to={'/createOrder'}>{texts.createNewOrder}</Link>
                    <Link className={'admin_pageLink'} to={'/artistInfo'}>{texts.artistInfo}</Link>
                    <Link className={'admin_pageLink'} to={'/reports'}>{texts.reports}</Link>
                </div> : ''}
                <div className="editUsers_container">
                    <h2 className="editUsersHeading">{texts.editUsers} :</h2>
                    <div className="designers_container">
                        <h3>{texts.designers}</h3>
                        <div className="designer_list">
                            {users.filter(u => u.designer).map((user) => {
                                return <UserRow key={uuid()} user={user} type={'Designer'}/>
                            })}
                        </div>
                        <h3 className="print_center_heading">{texts.printCenterAndOperator}</h3>
                        <div className="printCenter_list">
                            {users.filter(u => u.accessLevel === 4 || u.accessLevel === 9).map((user) => {
                                return <UserRow key={uuid()} user={user} type={'PrintCenter'}/>
                            })}
                        </div>
                        <h3>Пълни и кратки имена на продукти</h3>
                        <div className={'productNamesList'}>
                            {productNames.sort((b, a) => a.count - b.count).map((product) => <>
                                <li>({product.count}) {product.productName}: <br/><input type={'text'}
                                                                                         value={tempProds[`pid${product._id}`] || product.nickname}
                                                                                         onChange={(e) => {
                                                                                             let newTemp = {...tempProds}
                                                                                             newTemp[`pid${product._id}`] = e.target.value
                                                                                             setTempProds(newTemp)
                                                                                         }}
                                                                                         onBlur={e => {
                                                                                             updateProductNickname(product._id, e.target.value)
                                                                                         }}/>
                                    <hr/>
                                </li>
                            </>)}
                        </div>
                    </div>
                    <div className="artists_container">
                        <h3>{texts.artistsAndCount}</h3>
                        <div className="artist_list">
                            {users.filter(u => u.artist).map((user) => {
                                return <UserRow key={uuid()} user={user} type={'Artist'}/>
                            })}
                        </div>
                    </div>
                    <div className="printCenter_container">

                    </div>
                </div>
            </div> : <div>{texts.notAccess}</div>

    )

}

export default Admin;