import React, {memo, useState} from "react";
import OrderDropdown from './OrderDropdown'
import texts from "../config/translationsConfig";
import {useUserContext} from "../context/user/context";
import {formatFrameColor} from "../utils/functionUtils";
import {formatSize, formatProduct} from "../utils/functionUtils";
import './Order.css'
import uuid from "react-uuid";
import {serverFetch} from "../config/axiosConfig";
import Deadline from "./OrderPartials/Deadline";
import Price from "./OrderPartials/Price";
import CheckBox from "./Dropdown/CheckBox";
import PaymentCheckBox from "./Dropdown/PaymentCheckBox";
import DesignerWorkingCheck from "./OrderPartials/DesignerWorkingCheck";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSheetPlastic} from "@fortawesome/free-solid-svg-icons";
import Size from "./OrderPartials/Size";
import {useOrdersContext} from "../context/orders/context";

const Order = memo(({order, orderIndex, artists, designers, printCenter, user}) => {

    const orderDesigner = designers?.find(des => des.designer._id === order.designerId)?.nickname;
    // used for (2/2) statistic
    // const customerOrders = order.customer?.orders;
    // const activeOrders = customerOrders.filter(o => o.creationProgress !== 'fulfilled');

    const [artistPrice, setArtistPrice] = useState(order.artistPrice);
    const [priority, setPriority] = useState(order.priority);
    const [emergency, setEmergency] = useState(order.emergency);
    const [creationProgress, setCreationProgress] = useState(order.creationProgress);
    const [toggle, setToggle] = useState(false);
    const [artistPayment, setArtistPayment] = useState(order.artistPaid);
    const [artistPaid, setArtistPaid] = useState(artistPayment || order.artistPaid);
    const [orderRefunded, setOrderRefunded] = useState(order.refunded);
    const [artistSelected, setArtistSelected] = useState(false);
    const [creationProgressSelected, setCreationProgressSelected] = useState(false);
    const [notesFor, setNotesFor] = useState(order.notesFor);
    const [assignedArtist, setAssignedArtist] = useState(order.artistId);
    const [assignedDesigner, setAssignedDesigner] = useState(order.designerId);
    const {productNames} = useOrdersContext()

    const updateOrder = async (query, update) => {
        try {
            const response = await serverFetch(`/updateOrder`, {
                method: "post",
                data: {store: order.store, query, update}
            })
        } catch (e) {
            console.log(e)
        }
    }

    const updateUser = async (type, query, update) => {
        try {
            const response = await serverFetch(`/update${type}`, {
                method: "post",
                data: {query, update}
            })
        } catch (e) {
            console.log(e)
        }
    }

    const changePriority = () => {
        setPriority(!priority)
        updateOrder({_id: order._id}, {priority: !priority})
    }

    const changeEmergency = () => {
        setEmergency(!emergency)
        updateOrder({_id: order._id}, {emergency: !emergency})
    }

    const changeStatus = (newStatus) => {

        if (newStatus === 'printCenter') {
            updateOrder({_id: order._id}, {
                sentToPrint: Date.now(),
                sentToPrintIso: new Date(Date.now()).toLocaleDateString('en-UK')
            })
        }

        if (newStatus === 'finished' || newStatus === 'fulfilled') {

            // money is unused old prop now used to set the time when order is finished
            // and then should be used for artist monthly order filtering
            if (newStatus === 'finished') updateOrder({_id: order._id}, {money: Date.now()});
            if (newStatus === 'fulfilled') {
                updateOrder({_id: order._id},
                    {
                        sentToFulfilled: Date.now(),
                        sentToFulfilledIso: new Date(Date.now()).toLocaleDateString('en-UK')
                    });
            }

            updateUser('Designer', {_id: order.designerId}, {
                $pull: {assignedOrders: order._id},
                $addToSet: {fulfilledOrders: order._id}
            });
            updateUser('Artist', {_id: order.artistId}, {
                $pull: {assignedOrders: order._id},
                $addToSet: {fulfilledOrders: order._id}
            });
        }
        setCreationProgressSelected(true);
        setCreationProgress(newStatus);
        updateOrder({_id: order._id},
            {
                creationProgress: newStatus,
                $push: {progressHistory: {date: Date.now(), user: user.nickname, status: newStatus}}
            })
    }

    const changeNotesFor = (newNotesFor) => {

        setNotesFor(newNotesFor);
        setToggle(true);
        updateOrder({_id: order._id}, {notesFor: newNotesFor})
    }

    const assignDesignerToOrder = (designerId) => {

        if (designerId === 'Промени') {
            updateUser('Designer', {_id: order.designerId}, {$pull: {assignedOrders: order._id}});
            setCreationProgress('None');
            setAssignedDesigner('Промени');
            setCreationProgressSelected(true);

            updateOrder({_id: order._id}, {
                designerId: null,
                creationProgress: 'None',
                startDesign: 0,
                designDeadline: 0,
                $push: {progressHistory: {date: Date.now(), user: user.nickname, status: 'None'}}
            });
        } else {
            let designer = designers.find(d => d.designer._id === designerId)?.nickname;
            updateOrder({_id: order._id}, {
                designerId,
                creationProgress: 'designer',
                startDesign: Date.now(),
                designDeadline: Date.now() + 2 * 24 * 60 * 60 * 1000,
                $push: {progressHistory: {date: Date.now(), user: user.nickname, status: 'designer', designer}}
            });

            if (order.designerId) updateUser('Designer', {_id: order.designerId}, {$pull: {assignedOrders: order._id}});

            updateUser('Designer', {_id: designerId}, {$addToSet: {assignedOrders: order._id}});
            setCreationProgress('designer');
            setAssignedDesigner(designerId);
            setCreationProgressSelected(true);
        }
    }

    const assignArtistToOrder = (artistId) => {

        if (artistId === 'Промени') {

            updateUser('Artist', {_id: order.artistId}, {$pull: {assignedOrders: order._id}});
            setCreationProgress('None');
            setArtistSelected(true);
            setAssignedArtist('Промени');
            setCreationProgressSelected(true);

            updateOrder({_id: order._id}, {
                artistId: null,
                creationProgress: 'None',
                startPainting: 0,
                paintingDeadline: 0,
                $push: {progressHistory: {date: Date.now(), user: user.nickname, status: 'None'}}
            });
        } else {
            let artist = artists.find(a => a.artist._id === artistId)?.nickname;

            updateOrder({_id: order._id}, {
                artistId,
                creationProgress: 'painting',
                startPainting: Date.now(),
                paintingDeadline: Date.now() + 7 * 24 * 60 * 60 * 1000,
                $push: {progressHistory: {date: Date.now(), user: user.nickname, status: 'painting', artist}}
            });

            if (order.artistId) updateUser('Artist', {_id: order.artistId}, {$pull: {assignedOrders: order._id}});

            updateUser('Artist', {_id: artistId}, {$addToSet: {assignedOrders: order._id}});
            setCreationProgress('painting');
            setArtistSelected(true);
            setAssignedArtist(artistId);
            setCreationProgressSelected(true);
        }
    }

    const changeToggle = () => {
        setToggle(!toggle)
    }

    const shouldBeBlue = order.store === 'dog-hero' && order.orderNumber.indexOf('-') === -1 && order.orderNumber !== '';
    const shouldBeOrange = order.store === 'dog-land' && order.orderNumber.indexOf('-') === -1 && order.orderNumber !== '';
    const shouldBePoopy = order.store === 'custom-pet' && order.orderNumber.indexOf('-') === -1 && order.orderNumber !== '';
    return (
        <div
            className={`order__container ${(order.orderNumber.indexOf('-') !== -1 || order.orderNumber === '') ? "orderRowNoBorder" : ""}`}>
            <div
                className={`${emergency ? "orderRowRed" : priority ? "orderRowLightBlue" : ""} order__container__main 
                            ${printCenter ? 'print_center_row' : ""}
                            ${orderRefunded ? 'order_refunded' : ""}`}>
                <div className={`orderRow orderTable_num dropdown__clickable 
                    ${shouldBeBlue ? 'heroBackground' : shouldBeOrange ? 'landBackground' : shouldBePoopy?'poopyBackground':""}`}
                     onClick={changeToggle}>
                    {order?.note ?
                        <><FontAwesomeIcon className="order_note_icon"
                                           title={texts.clientNote}
                                           icon={faSheetPlastic}
                                           color={'red'}
                        /><span>&nbsp;</span></> : ""}
                    {order.orderNumber}</div>
                <div className="orderRow orderTable_date dropdown__clickable"
                     onClick={changeToggle}>{new Date(order.orderDate).toLocaleDateString("en-UK")}</div>
                <div className={`orderRow orderTable_customer dropdown__clickable
                    ${order.customer?.shopifyOrders > 1 && order.orderNumber?.indexOf('-') === -1 && order?.orderNumber !== '' ? 'deadline_red_color' : ""}
                    ${order.trackingNumber && order.creationProgress !== 'fulfilled' ? 'dropdown_order_tracking_appended' : ""}`}
                     onClick={changeToggle}><span>{order.customer?.name}</span>
                    {/*<span> ({activeOrders.length}/{customerOrders.length})</span>*/}
                    <span> {order.customer?.shopifyOrders > 1 && order.orderNumber.indexOf('-') === -1 && order.orderNumber !== '' ? ` - ${order.customer?.shopifyOrders}` : ''}</span>
                </div>
                <Size order={order} printCenter={printCenter}/>
                <div className="orderRow orderTable_count dropdown__clickable"
                     onClick={changeToggle}>{order.frameColor !== '' ? order.frameColor : formatFrameColor(order, order.portraitSize)}</div>
                <div
                    className="orderRow orderTable_product dropdown__clickable"
                    onClick={changeToggle}>{formatProduct(order.orderNumber, order.productName,productNames)}</div>

                <Price order={order} printCenter={printCenter}/>
                <Deadline order={order} printCenter={printCenter}/>
                <div className="orderRow orderTable_status">
                    {!printCenter ? <select onChange={(e) => changeStatus(e.target.value)}
                                            className={`status_options ${creationProgressSelected ? creationProgress : order.creationProgress !== 'None' ? order.creationProgress : ""}_option`}
                                            value={creationProgressSelected ? creationProgress : order.creationProgress !== 'None' ? order.creationProgress : texts.changeStatus}>
                        <option value={'None'}>{texts.changeStatus}</option>
                        <option className="designer_option" value={"designer"}>{texts.designerRole}</option>
                        <option className="printCenter_option" value={"printCenter"}>{texts.printCenterRole}</option>
                        <option className="ready_option" value={"ready"}>{texts.ready}</option>
                        <option className="painting_option" value={"painting"}>{texts.painting}</option>
                        <option className="atoBeSpecified_option"
                                value={"atoBeSpecified"}>{texts.toBeSpecified}</option>
                        <option className="finished_option" value={"finished"}>{texts.finished}</option>
                        <option className="fulfilled_option" value={"fulfilled"}>{texts.fulfilled}</option>
                    </select> : <div><select onChange={(e) => changeStatus(e.target.value)}
                                             className={`status_options ${creationProgressSelected ? creationProgress : order.creationProgress !== 'None' ? order.creationProgress : ""}_option`}
                                             value={creationProgressSelected ? creationProgress : order.creationProgress !== 'None' ? order.creationProgress : texts.changeStatus}>
                        <option value={texts.changeStatus} disabled>{texts.changeStatus}</option>
                        <option className="designer_option" value={"designer"}>{texts.designerRole}</option>
                        <option className="printCenter_option" value={"printCenter"}>{texts.printCenterRole}</option>
                        <option className="atoBeSpecified_option"
                                value={"atoBeSpecified"}>{texts.toBeSpecified}</option>
                        <option className="finished_option" value={"finished"} disabled>{texts.finished}</option>
                    </select>
                    </div>}
                </div>
                {!printCenter ? <div className="orderRow orderTable_designer">
                        <select onChange={(e) => assignDesignerToOrder(e.target.value)}
                                className={`status_options ${assignedDesigner ? 'designer_option' : ''}`}
                                value={assignedDesigner ? assignedDesigner : texts.changeStatus}>
                            <option value={texts.changeStatus}>{texts.changeStatus}</option>
                            {designers?.map((designer) => {
                                return <option key={uuid()} value={designer.designer._id}>{designer.nickname}</option>
                            })}
                        </select>
                    </div> :
                    <div className="orderRow orderTable_designer">
                        {order.designerId ? orderDesigner : ''}
                    </div>
                }
                {!printCenter ? <div className="orderRow orderTable_artist">
                    <select id={`select_${order._id}`} name={`select_${order._id}`}
                            onChange={(e) => assignArtistToOrder(e.target.value)}
                            className={`status_options ${artistPayment ? 'artist__paid' : ''} ${assignedArtist ? 'painting_option ' : ''} ${user.accessLevel > 7 && assignedArtist ? 'artist_status_options ' : ''}`}
                            value={artistSelected ? assignedArtist : order.artistId ? order.artistId : texts.changeStatus}>
                        <option value={texts.changeStatus}>{texts.changeStatus}</option>
                        {artists?.map((artist) => {
                            return <option key={uuid()} value={artist.artist._id}>{artist.nickname}</option>
                        })}
                    </select>
                    {(artistSelected || order.artistId) && user.accessLevel > 7 ?
                        <span className="payment__input">
                           <PaymentCheckBox
                               initialCheck={artistPaid}
                               setNewStatus={setArtistPayment}
                               query={{_id: order._id}}
                               updateProp={'artistPaid'}
                               artistPrice={artistPrice}
                               order={order}
                           />
                    </span> : ""}
                </div> : ''}
                {printCenter ? <div
                    className="orderRow orderTable_sentToPrint dropdown__clickable"
                    onClick={changeToggle}>{order.sentToPrint > 0 ? new Date(order.sentToPrint).toLocaleDateString("en-UK") : ''}</div> : ''}
                <div className={`orderRow orderTable_notes`}>
                    <div className={`dropdown__clickable`}
                    >
                        <select onChange={(e) => changeNotesFor(e.target.value)}
                                className={`status_options ${order.notes.length ? 'order_has_note' : ""}`}
                                value={notesFor !== '' ? notesFor : texts.upload}
                        >
                            <option value={null}>{texts.upload}</option>
                            <option value={"all"}>{texts.all}</option>
                            <option value={"designer"}>{texts.designerRole}</option>
                            <option value={"printCenter"}>{texts.printCenterRole}</option>
                            <option value={"artist"}>{texts.artistRole}</option>
                            <option value={"operator"}>{texts.operator}</option>
                        </select>
                    </div>
                </div>
                {!printCenter ? <div className="orderRow orderTable_priority">
                    <span className="dropdown__clickable__emergency__span">
                    <input className="dropdown__clickable__emergency" onChange={changeEmergency} type="checkbox"
                           checked={`${emergency ? "checked" : ""}`}/>
                    </span>
                        <span className="dropdown__clickable__prior__span">
                        <input className="dropdown__clickable__priority" onChange={changePriority} type="checkbox"
                               checked={`${priority ? "checked" : ""}`}/>
                    </span>
                    </div> :
                    <div className="orderRow printTable_working">
                        <DesignerWorkingCheck order={order} updateOrder={updateOrder} propToChange={'downloaded'}/>
                    </div>

                }
            </div>
            <div className="orderTable__dropdown">
                {toggle ?
                    <OrderDropdown
                        key={uuid()}
                        order={order}
                        orderIndex={orderIndex}
                        artistPrice={artistPrice}
                        setArtistPrice={setArtistPrice}
                        artistSelected={artistSelected}
                        orderRefunded={orderRefunded}
                        setOrderRefunded={setOrderRefunded}
                        setCreationProgress={setCreationProgress}
                        notesFor={notesFor}
                    /> : ""}
            </div>
        </div>
    )
})

export default Order;