import React from 'react';
import ReactDOM from "react-dom/client";
import {CookiesProvider} from 'react-cookie';
//Components
import App from './App';

//Providers
import {AppProvider} from './context/appContext';
import {UserProvider} from './context/user/context';
import {OrdersProvider} from './context/orders/context';

//Styles
import './index.css';

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <CookiesProvider>
        <AppProvider>
            <UserProvider>
                <OrdersProvider>
                    <App/>
                </OrdersProvider>
            </UserProvider>
        </AppProvider>
    </CookiesProvider>
);
