export const GET_ORDERS_BEGIN = "GET_ORDERS_BEGIN";
export const GET_ORDERS_SUCCESS = "GET_ORDERS_SUCCESS";
export const GET_ORDERS_ERROR = "GET_ORDERS_ERROR";
export const SET_ORDERS = "SET_ORDERS";
export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const SET_LEADERBOARD = "SET_LEADERBOARD";
export const UPDATE_ORDER_BEGIN = "UPDATE_ORDER_BEGIN";
export const UPDATE_ORDER_SUCCESS = "UPDATE_ORDER_SUCCESS";
export const UPDATE_ORDER_ERROR = "UPDATE_ORDER_ERROR";
export const UPLOAD_IMAGE_END = "UPLOAD_IMAGE_END";
export const UPLOAD_IMAGE_ERROR = "UPLOAD_IMAGE_ERROR";
export const DELETE_IMAGE_END = "DELETE_IMAGE_END";
export const DELETE_IMAGE_ERROR = "DELETE_IMAGE_ERROR";
export const GET_PRODNAMES_SUCCESS = "GET_PRODNAMES_SUCCESS";

