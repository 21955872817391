import texts from "../../config/translationsConfig";
import {useEffect, useState} from "react";
import './Deadline.css'
import {serverFetch} from "../../config/axiosConfig";
import {useFirstRender} from "../../utils/functionUtils";

const Price = ({order, printCenter}) => {

    const [price, setPrice] = useState(order.priceAfterDiscount?.toFixed(1) || 0);
    const [debouncedPrice, setDebouncedPrice] = useState(price);
    const [editPrice, setEditPrice] = useState(false);

    const updatePrice = async (newPrice) => {
        try {
            const response = await serverFetch(`/updateOrder`, {
                method: "post",
                data: {
                    store: order.store,
                    query: {_id: order._id},
                    update: {priceAfterDiscount: Number(newPrice)}
                }
            })
        } catch (e) {
            console.log('price not changed')
        }
    }

    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedPrice(price);
        }, 1500);

        return () => {
            clearTimeout(timerId);
        };
    }, [price]);

    const firstRender = useFirstRender();

    useEffect(() => {
        if (!firstRender) {
            setEditPrice(false);
            updatePrice(price)
        }
    }, [debouncedPrice]);

    return (
        <div className={`orderRow orderTable_price dropdown__clickable ${printCenter ? 'cursor_none' : ''}`}>
            {editPrice && !printCenter ?
                <input value={price} onChange={(e) => setPrice(e.target.value)} className="edit_price_input"/> :
                <div title={printCenter ? '' : texts.editPrice} onClick={() => setEditPrice(!editPrice)}>${price}</div>}
        </div>
    )
}

export default Price;