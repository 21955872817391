import axios from 'axios';

export const isProduction = process.env.NODE_ENV !== "development";

//Set server URLS
export const shopifyDashboardServerHost = isProduction ? "https://api-1.shop-art.xyz" : "http://127.0.0.1:3090";

export const serverFetch = axios.create({
    withCredentials: true,
    credentials: "include",
    baseURL: `${shopifyDashboardServerHost}`,
});

serverFetch.interceptors.response.use(async (response) => {
    if (response.data.needAuth) {
//        window.location.href = '/login'
    }
    return response;
}, (error) => {
    if (error) {
        console.log(error)
    }
    return Promise.reject(error);
});