import {useUserContext} from "../../context/user/context";
import React, {useState} from "react";
import {serverFetch} from "../../config/axiosConfig";
import texts from "../../config/translationsConfig";
import Button from "../Partials/Button/Button";
import Modal from "react-modal";

import './ProgressHistory.css'
import uuid from "react-uuid";

const ProgressHistory = ({order}) => {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            maxWidth: '80%',
            maxHeight: '80%'
        },
    };

    const [modalIsOpen, setIsOpen] = useState(false);


    async function afterOpenModal() {
        // references are now sync'd and can be accessed.
        console.log('progress: ', order)
    }

    function closeModal() {
        setIsOpen(false);
    }

    function formatStatus(status) {
        let text = '';
        switch (status) {
            case 'None':
                text = texts.changeStatus;
                break
            case 'designer':
                text = texts.designerRole;
                break
            case 'painting':
                text = texts.painting;
                break
            case 'printCenter':
                text = texts.printCenterRole;
                break
            case 'atoBeSpecified':
                text = texts.toBeSpecified;
                break
            case 'ready':
                text = texts.ready;
                break
            case 'finished':
                text = texts.finished;
                break
            case 'fulfilled':
                text = texts.fulfilled;
                break
            default:
                break
        }
        return text;
    }

    return (
        <div>
            <div onClick={() => setIsOpen(true)} className="progress_history_button add_tracking_button">
                {texts.seeProgressHistory}
            </div>
            <Modal
                ariaHideApp={false}
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <button className="close__modal__btn progress_history_close_btn"
                        onClick={closeModal}>{texts.close}</button>
                <div className="progress_history_modal">{order.progressHistory?.map((statusChange) => {
                    return <div key={uuid()}>
                        <span>{new Date(statusChange.date).toLocaleDateString("en-UK")} </span><span
                        className="dropdown_username">{statusChange.user}: </span>
                        <span>{formatStatus(statusChange.status)}</span>
                        {statusChange.designer ? <span> - {statusChange.designer}</span> : ''}
                        {statusChange.artist ? <span> - {statusChange.artist}</span> : ''}
                    </div>
                })}</div>
            </Modal>

        </div>
    )
}

export default ProgressHistory;