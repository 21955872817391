// components

// icons
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faArrowUp} from '@fortawesome/free-solid-svg-icons'
import React, {useEffect, useState} from "react";
import {useUserContext} from "../context/user/context";
import texts from "../config/translationsConfig";
import {serverFetch} from "../config/axiosConfig";
import './ArtistInfo.css';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import uuid from "react-uuid";

const ArtistInfo = () => {

    const scrollToTop = () => {
        document.body.scrollTo({top: 0, left: 0, behavior: "smooth"})
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
    }

    const {user} = useUserContext();
    const [startDate, setStartDate] = useState(new Date(Date.now()).setHours(0, 0, 0, 0))
    const [finalDate, setFinalDate] = useState(new Date(Date.now()).setHours(23, 59, 59, 999))
    const [artists, setArtists] = useState([])
    const [frames, setFrames] = useState([])
    let sumOfOrders = 0;
    let sumOfMoney = 0;
    let sumOfFrames = 0;

    const getArtistInfo = async () => {
        const response = await serverFetch(`/getArtistInfo?startDate=${startDate}&&finalDate=${finalDate}`, {
            method: "get",
        });
        console.log('SERVER ARTIST INFO RESPONSE: ', response.data);
        setArtists(response.data.artists);
        setFrames(response.data.frames);
    }

    useEffect(() => {
        getArtistInfo();
    }, [startDate, finalDate])
    return (
        user?.accessLevel > 7 ?

            <div className="home__container">
                <button onClick={scrollToTop}
                        style={{
                            background: "#359df7",
                            position: "fixed",
                            bottom: "35px",
                            right: "35px",
                            borderRadius: "7px",
                            zIndex: "10"
                        }}><FontAwesomeIcon
                    icon={faArrowUp} size="3x"/></button>
                <div className={'artistInfo_heading'}>{texts.artistInfoHeading}</div>
                <span className={'artist_info_date_container'}>
                    <span>{texts.chooseStartDate}</span>
                    <DatePicker className={'artist__date_picker'} selected={startDate} dateFormat="dd/MM/yyyy"
                                onChange={(date) => setStartDate(date.setHours(0, 0, 0, 0))}/>
                </span>
                <span className={'artist_info_date_container'}>
                    <span>{texts.chooseFinalDate}</span>
                 <DatePicker className={'artist__date_picker'}
                             selected={finalDate} dateFormat="dd/MM/yyyy"
                             onChange={(date) => setFinalDate(date.setHours(23, 59, 59, 999))}/>
                </span>

                <div className="artist_info_artists_container">
                    <div className={'artistInfo_artist_col artistInfo_blue'}>{texts.name}</div>
                    <div className={'artistInfo_artist_col artistInfo_blue'}>{texts.count}</div>
                    <div className={'artistInfo_artist_col artistInfo_blue'}>{texts.paymentSum}</div>
                    {artists?.map((artist) => {
                        sumOfOrders += artist?.ordersCount;
                        sumOfMoney += artist?.allMoney
                        return (
                            <div className={'artistInfo_row'} key={uuid()}>
                                <div className={'artistInfo_artist_col'}>{artist?.nickname}</div>
                                <div className={'artistInfo_artist_col'}>{artist?.ordersCount}</div>
                                <div className={'artistInfo_artist_col'}>{artist?.allMoney}</div>
                            </div>
                        )
                    })}
                    <div className={'artistInfo_artist_col artistInfo_green'}>{texts.allFrames}</div>
                    <div className={'artistInfo_artist_col artistInfo_green'}>{sumOfOrders}</div>
                    <div className={'artistInfo_artist_col artistInfo_green'}>{sumOfMoney}</div>
                </div>
                <div className="artist_info_frames_container">
                    <div className={'artistInfo_artist_col artistInfo_blue'}>{texts.frame}</div>
                    <div className={'artistInfo_artist_col artistInfo_blue'}>{texts.count}</div>

                    {/* frames come from back end user controller as an object and are rendered as an array of arrays */}
                    {Object.entries(frames).map((arr) => {
                        sumOfFrames += Number(arr[1]);
                        return (
                            <div className={'artistInfo_row'} key={uuid()}>
                                <div className={'artistInfo_artist_col'}>{arr[0]}</div>
                                <div className={'artistInfo_artist_col'}>{arr[1]}</div>
                            </div>
                        )
                    })
                    }
                    <div className={'artistInfo_artist_col artistInfo_green'}>{texts.allFrames}</div>
                    <div className={'artistInfo_artist_col artistInfo_green'}>{sumOfFrames}</div>
                </div>
            </div> : ''
    )
}

export default ArtistInfo;